import { useState, useEffect } from "react";
import {
  MessageSquare,
  Phone,
  Video,
  Mail,
  Calendar,
  Plus,
  Edit2,
  Trash2,
  AlertCircle,
  CheckCircle,
  Clock,
  ChevronRight,
  Filter,
} from "lucide-react";

const CommunicationsTab = ({ userId }) => {
  const [communications, setCommunications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedCommunication, setSelectedCommunication] = useState(null);
  const [filter, setFilter] = useState("all");
  const [notification, setNotification] = useState(null);

  const fetchCommunications = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/users/${userId}/communications`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch communications");
      const data = await response.json();
      setCommunications(data.communications);
    } catch (error) {
      showNotification("Failed to fetch communications", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCommunications();
  }, [userId]);

  const showNotification = (message, type = "success") => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleDelete = async (communicationId) => {
    if (
      !window.confirm("Are you sure you want to delete this communication log?")
    )
      return;

    try {
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/users/${userId}/communications/${communicationId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to delete communication");

      await fetchCommunications();
      showNotification("Communication deleted successfully");
    } catch (error) {
      showNotification("Failed to delete communication", "error");
    }
  };

  const getTypeIcon = (type) => {
    switch (type) {
      case "email":
        return Mail;
      case "phone":
        return Phone;
      case "meeting":
        return Video;
      default:
        return MessageSquare;
    }
  };

  const FilterButton = ({ value, label, currentFilter, onChange }) => (
    <button
      onClick={() => onChange(value)}
      className={`px-3 py-1.5 text-sm font-medium rounded-md ${
        currentFilter === value
          ? "bg-indigo-50 text-indigo-700"
          : "text-gray-500 hover:text-gray-700 hover:bg-gray-50"
      }`}
    >
      {label}
    </button>
  );

  const CommunicationForm = ({ onSubmit, initialData = null, onClose }) => {
    const [formData, setFormData] = useState(
      initialData || {
        type: "email",
        subject: "",
        content: "",
        outcome: "",
        nextSteps: "",
        followUpDate: "",
      }
    );

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const url = initialData
          ? `https://api.instacorp.ae/api/admin/data/users/${userId}/communications/${initialData._id}`
          : `https://api.instacorp.ae/api/admin/data/users/${userId}/communications`;

        const response = await fetch(url, {
          method: initialData ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) throw new Error("Failed to save communication");

        await fetchCommunications();
        showNotification(
          initialData
            ? "Communication updated successfully"
            : "Communication logged successfully"
        );
        onClose();
      } catch (error) {
        showNotification("Failed to save communication", "error");
      }
    };

    return (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-xl w-full mx-4">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            {initialData ? "Edit Communication" : "Log New Communication"}
          </h3>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Type
              </label>
              <select
                value={formData.type}
                onChange={(e) =>
                  setFormData({ ...formData, type: e.target.value })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="email">Email</option>
                <option value="phone">Phone Call</option>
                <option value="meeting">Meeting</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Subject
              </label>
              <input
                type="text"
                value={formData.subject}
                onChange={(e) =>
                  setFormData({ ...formData, subject: e.target.value })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Content
              </label>
              <textarea
                value={formData.content}
                onChange={(e) =>
                  setFormData({ ...formData, content: e.target.value })
                }
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Outcome
              </label>
              <textarea
                value={formData.outcome}
                onChange={(e) =>
                  setFormData({ ...formData, outcome: e.target.value })
                }
                rows={2}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Next Steps
              </label>
              <textarea
                value={formData.nextSteps}
                onChange={(e) =>
                  setFormData({ ...formData, nextSteps: e.target.value })
                }
                rows={2}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Follow-up Date
              </label>
              <input
                type="date"
                value={formData.followUpDate}
                onChange={(e) =>
                  setFormData({ ...formData, followUpDate: e.target.value })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <div className="flex justify-end space-x-3 mt-6">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
              >
                {initialData ? "Update" : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const CommunicationCard = ({ communication }) => {
    const Icon = getTypeIcon(communication.type);
    const hasFollowUp =
      communication.followUpDate &&
      new Date(communication.followUpDate) > new Date();

    return (
      <div className="bg-white p-6 rounded-lg border border-gray-200 hover:border-indigo-300 transition-colors">
        <div className="flex justify-between items-start">
          <div className="flex items-start space-x-4">
            <div className="p-2 bg-indigo-50 rounded-lg">
              <Icon className="h-5 w-5 text-indigo-600" />
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-900">
                {communication.subject}
              </h4>
              <p className="text-sm text-gray-500 mt-1">
                {communication.content}
              </p>

              {communication.outcome && (
                <div className="mt-3">
                  <p className="text-sm font-medium text-gray-900">Outcome</p>
                  <p className="text-sm text-gray-500">
                    {communication.outcome}
                  </p>
                </div>
              )}

              {communication.nextSteps && (
                <div className="mt-3">
                  <p className="text-sm font-medium text-gray-900">
                    Next Steps
                  </p>
                  <p className="text-sm text-gray-500">
                    {communication.nextSteps}
                  </p>
                </div>
              )}

              <div className="flex items-center space-x-4 mt-4">
                <div className="flex items-center text-sm text-gray-500">
                  <Calendar className="h-4 w-4 mr-1.5" />
                  {new Date(communication.dateCreated).toLocaleDateString()}
                </div>

                {hasFollowUp && (
                  <div className="flex items-center text-sm text-yellow-600">
                    <Clock className="h-4 w-4 mr-1.5" />
                    Follow-up:{" "}
                    {new Date(communication.followUpDate).toLocaleDateString()}
                  </div>
                )}

                {communication.createdByUser && (
                  <div className="flex items-center text-sm text-gray-500">
                    <span className="font-medium">
                      {communication.createdByUser.first_name}{" "}
                      {communication.createdByUser.last_name}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex space-x-2">
            <button
              onClick={() => setSelectedCommunication(communication)}
              className="p-1 text-gray-400 hover:text-gray-600"
            >
              <Edit2 className="h-4 w-4" />
            </button>
            <button
              onClick={() => handleDelete(communication._id)}
              className="p-1 text-gray-400 hover:text-red-600"
            >
              <Trash2 className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const filteredCommunications = communications.filter((comm) => {
    if (filter === "all") return true;
    if (filter === "followup") {
      return comm.followUpDate && new Date(comm.followUpDate) > new Date();
    }
    return comm.type === filter;
  });

  return (
    <div className="space-y-6">
      {notification && (
        <div
          className={`p-4 rounded-md ${
            notification.type === "error"
              ? "bg-red-50 text-red-800"
              : "bg-green-50 text-green-800"
          }`}
        >
          {notification.message}
        </div>
      )}

      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-medium text-gray-900">
            Communication History
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Track all communications and follow-ups with the user
          </p>
        </div>
        <button
          onClick={() => setShowAddModal(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          Log Communication
        </button>
      </div>

      <div className="flex space-x-4 border-b border-gray-200 pb-4">
        <FilterButton
          value="all"
          label="All"
          currentFilter={filter}
          onChange={setFilter}
        />
        <FilterButton
          value="email"
          label="Email"
          currentFilter={filter}
          onChange={setFilter}
        />
        <FilterButton
          value="phone"
          label="Phone"
          currentFilter={filter}
          onChange={setFilter}
        />
        <FilterButton
          value="meeting"
          label="Meeting"
          currentFilter={filter}
          onChange={setFilter}
        />
        <FilterButton
          value="followup"
          label="Follow-ups"
          currentFilter={filter}
          onChange={setFilter}
        />
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
        </div>
      ) : (
        <div className="space-y-4">
          {filteredCommunications.length === 0 ? (
            <div className="text-center py-12">
              <MessageSquare className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No communications
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by logging a new communication.
              </p>
            </div>
          ) : (
            filteredCommunications.map((communication) => (
              <CommunicationCard
                key={communication._id}
                communication={communication}
              />
            ))
          )}
        </div>
      )}

      {/* Add/Edit Communication Modal */}
      {showAddModal && (
        <CommunicationForm
          onClose={() => setShowAddModal(false)}
          onSubmit={fetchCommunications}
        />
      )}

      {/* Edit Communication Modal */}
      {selectedCommunication && (
        <CommunicationForm
          initialData={selectedCommunication}
          onClose={() => setSelectedCommunication(null)}
          onSubmit={fetchCommunications}
        />
      )}
    </div>
  );
};

export default CommunicationsTab;
