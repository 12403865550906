import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Building2, Loader, Save, X, AlertCircle } from 'lucide-react';

const EditCompanyForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await fetch(
          `https://api.instacorp.ae/api/admin/data/companies/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        if (!response.ok) throw new Error('Failed to fetch company');
        const data = await response.json();
        
        // Ensure all required nested objects exist
        if (!data.companyNames) data.companyNames = { first: { english: '', arabic: '' } };
        if (!data.companyNames.first) data.companyNames.first = { english: '', arabic: '' };
        if (!data.companyDetails) data.companyDetails = { typeOfCompany: '', location: '', typeOfTradeZone: '' };
        if (!data.licenseType) data.licenseType = { name: '' };
        if (!data.email) data.email = '';
        if (!data.phone) data.phone = '';
        
        setFormData(data);
      } catch (error) {
        console.error('Error fetching company:', error);
        setError('Failed to load company data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompany();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    
    try {
      // Create a copy of formData and remove the _id field
      const dataToSend = { ...formData };
      delete dataToSend._id;
      
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/companies/${id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(dataToSend),
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || 'Failed to update company');
      }
      
      setSuccessMessage('Company updated successfully');
      
      // Navigate after a short delay to show the success message
      setTimeout(() => {
        navigate(`/companies/${id}`);
      }, 1500);
    } catch (error) {
      console.error('Error updating company:', error);
      setError(error.message || 'Failed to update company. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e, section, subsection) => {
    const { name, value } = e.target;
    
    // Use JSON parse/stringify for proper deep cloning
    setFormData(prev => {
      const newFormData = JSON.parse(JSON.stringify(prev));
      
      if (section && subsection) {
        // Ensure nested paths exist
        if (!newFormData[section]) newFormData[section] = {};
        if (!newFormData[section][subsection]) newFormData[section][subsection] = {};
        
        newFormData[section][subsection][name] = value;
      } else if (section) {
        if (!newFormData[section]) newFormData[section] = {};
        newFormData[section][name] = value;
      } else {
        newFormData[name] = value;
      }
      
      return newFormData;
    });
  };

  // Improved handler for application steps
  const handleStepChange = (index, newStatus) => {
    setFormData(prev => {
      const newData = JSON.parse(JSON.stringify(prev));
      
      // Update the step status
      newData.applicationSteps[index] = {
        ...newData.applicationSteps[index],
        status: newStatus,
        updatedAt: new Date().toISOString(),
        // If approved, set completedAt
        ...(newStatus === 'approved' ? { completedAt: new Date().toISOString() } : {})
      };
      
      return newData;
    });
  };

  const FormSection = ({ title, children }) => (
    <div className="bg-white rounded-lg border border-gray-200 shadow-sm transition-all hover:shadow-md">
      <div className="border-b border-gray-200 px-6 py-4 bg-gray-50">
        <h2 className="text-lg font-medium text-gray-900">{title}</h2>
      </div>
      <div className="px-6 py-4">
        {children}
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-3xl mx-auto">
        <div className="mb-6">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Company
          </button>
        </div>

        {error && (
          <div className="mb-6 flex items-center gap-2 p-4 border border-red-200 bg-red-50 text-red-700 rounded-md">
            <AlertCircle className="w-5 h-5 flex-shrink-0" />
            <p>{error}</p>
            <button 
              className="ml-auto text-red-500 hover:text-red-700"
              onClick={() => setError(null)}
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        )}

        {successMessage && (
          <div className="mb-6 p-4 border border-green-200 bg-green-50 text-green-700 rounded-md">
            {successMessage}
          </div>
        )}

        <div className="flex items-center mb-6">
          <div className="w-12 h-12 rounded-full bg-indigo-100 flex items-center justify-center mr-4">
            <Building2 className="w-6 h-6 text-indigo-600" />
          </div>
          <h1 className="text-2xl font-bold text-gray-900">
            Edit {formData?.companyNames?.first?.english || 'Company'}
          </h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <FormSection title="Company Status">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Status
              </label>
              <select
                name="status"
                className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                value={formData.status || ''}
                onChange={(e) => handleChange(e)}
              >
                <option value="processing">Processing</option>
                <option value="live">Live</option>
                <option value="imported">Imported</option>
              </select>
            </div>
            
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Active Status
              </label>
              <select
                name="isActive"
                className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                value={formData.isActive.toString()}
                onChange={(e) => handleChange(e)}
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
          </FormSection>

          <FormSection title="Basic Information">
            <div className="grid grid-cols-1 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Company Name (English)
                </label>
                <input
                  type="text"
                  name="english"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                  value={formData.companyNames?.first?.english || ''}
                  onChange={(e) => handleChange(e, 'companyNames', 'first')}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Company Name (Arabic)
                </label>
                <input
                  type="text"
                  name="arabic"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                  value={formData.companyNames?.first?.arabic || ''}
                  onChange={(e) => handleChange(e, 'companyNames', 'first')}
                  dir="rtl"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Company Type
                </label>
                <select
                  name="typeOfCompany"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                  value={formData.companyDetails?.typeOfCompany || ''}
                  onChange={(e) => handleChange(e, 'companyDetails')}
                >
                  <option value="">Select type</option>
                  <option value="Free Zone">Free Zone</option>
                  <option value="Mainland">Mainland</option>
                  <option value="LLC">LLC</option>
                  <option value="FZE">FZE</option>
                  <option value="Branch">Branch</option>
                </select>
              </div>
            </div>
          </FormSection>

          <FormSection title="Contact Information">
            <div className="grid grid-cols-1 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                  value={formData.email || ''}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phone"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                  value={formData.phone || ''}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </FormSection>

          <FormSection title="Location & License">
            <div className="grid grid-cols-1 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Location
                </label>
                <input
                  type="text"
                  name="location"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                  value={formData.companyDetails?.location || ''}
                  onChange={(e) => handleChange(e, 'companyDetails')}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Jurisdiction
                </label>
                <select
                  name="typeOfTradeZone"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                  value={formData.companyDetails?.typeOfTradeZone || ''}
                  onChange={(e) => handleChange(e, 'companyDetails')}
                >
                  <option value="">Select trade zone</option>
                  <option value="SHAMS">SHAMS</option>
                  <option value="IFZA">IFZA</option>
                </select>
              </div>

              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Office Type
                </label>
                <select
                  name="typeOfOffice"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                  value={formData.typeOfOffice || ''}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select office type</option>
                  <option value="Virtual">Virtual</option>
                  <option value="Physical">Physical</option>
                  <option value="Flexi">Flexi</option>
                </select>
              </div>
            </div>
          </FormSection>

          <FormSection title="Application Steps">
            <div className="space-y-4">
              {formData.applicationSteps?.map((step, index) => (
                <div key={index} className="flex flex-col md:flex-row md:items-center justify-between p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors">
                  <div className="mb-3 md:mb-0 md:mr-4 flex-grow">
                    <h3 className="font-medium text-gray-900">{step.name}</h3>
                    <p className="text-sm text-gray-500 mt-1">{step.description}</p>
                    {step.completedAt && (
                      <p className="text-xs text-gray-400 mt-1">
                        Completed: {new Date(step.completedAt).toLocaleDateString()}
                      </p>
                    )}
                  </div>
                  <div className="flex-shrink-0">
                    <select
                      value={step.status || 'pending'}
                      onChange={(e) => handleStepChange(index, e.target.value)}
                      className={`border rounded-md shadow-sm p-2 focus:ring-2 focus:outline-none focus:ring-opacity-50 ${
                        step.status === 'approved' 
                          ? 'bg-green-50 border-green-200 text-green-700 focus:ring-green-500' 
                          : step.status === 'rejected'
                          ? 'bg-red-50 border-red-200 text-red-700 focus:ring-red-500'
                          : step.status === 'in_progress'
                          ? 'bg-blue-50 border-blue-200 text-blue-700 focus:ring-blue-500'
                          : 'border-gray-300 focus:ring-indigo-500'
                      }`}
                    >
                      <option value="pending">Pending</option>
                      <option value="in_progress">In Progress</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                    </select>
                  </div>
                </div>
              ))}
            </div>
          </FormSection>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors flex items-center"
            >
              {isSubmitting ? (
                <>
                  <Loader className="w-4 h-4 mr-2 animate-spin" />
                  Saving...
                </>
              ) : (
                <>
                  <Save className="w-4 h-4 mr-2" />
                  Save Changes
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCompanyForm;