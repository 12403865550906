import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Toaster } from 'react-hot-toast';

import Login from './components/Login';
import './index.css'
import BlogPost from "./components/BlogPost";
import Blog from "./components/Blog";
import ApplicationsPage from "./components/Applications";
import CompanyDetailsPage from "./components/ApplicationDetail";
import TradeZonesPage from "./components/Packages";
import LiveCompaniesPage from "./components/Companies";
import ImportCompany from "./components/ImportCompany";
import ServicesPage from "./components/Services";
import UserDetailPage from "./components/Users/UserDetail";
import UserEditPage from "./components/Users/UserEdit";
import UserCreatePage from "./components/Users/UserCreate";
import UsersPage from "./components/Users/UserPage";
import CompanyDetailView from "./components/Users/utils/CompanyDetailView";
import EditCompanyForm from "./components/Users/utils/EditCompany";
import ServiceDetailView from "./components/Users/utils/ServicesDetailView";
import TicketsPage from "./components/Users/TicketPage";
import TicketDetailPage from "./components/TicketDetailPage";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 4000,
            style: {
              background: '#363636',
              color: '#fff',
            },
            success: {
              duration: 3000,
            },
            error: {
              duration: 4000,
            },
          }}
        />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/users/new" element={<UserCreatePage />} />
          <Route path="/users/:id" element={<UserDetailPage />} />
          <Route path="/users/:id/edit" element={<UserEditPage />} />
          <Route path="/companies/:id" element={<CompanyDetailView />} />
          <Route path="/companies/:id/edit" element={<EditCompanyForm />} />
          <Route path="/companies/:id/services/:serviceId" element={<ServiceDetailView />} />
          <Route path="/tickets" element={<TicketsPage />} />
          <Route path="/tickets/:id" element={<TicketDetailPage />} />
          <Route path="/packages" element={<TradeZonesPage />} />
          <Route path="/applications" element={<ApplicationsPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/import-company" element={<ImportCompany />} />
          <Route path="/companies" element={<LiveCompaniesPage />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/applications/:companyId" element={<CompanyDetailsPage />} />
        </Routes>
        <ReactQueryDevtools initialIsOpen={false} />
      </Router>
    </QueryClientProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);