import { useEffect, useState } from "react";
import {
  Calendar,
  Building2,
  Plus,
  BriefcaseIcon,
  Mail,
  Phone,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

const CompaniesTab = ({ userId }) => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(
          `https://api.instacorp.ae/api/admin/data/user/companies?userId=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
       
        if (!response.ok) throw new Error("Failed to fetch companies");
        const data = await response.json();
        setCompanies(data.companies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanies();
  }, [userId]);

  const getStatusColor = (status) => {
    const statusColors = {
      processing: "bg-yellow-100 text-yellow-800",
      approved: "bg-green-100 text-green-800",
      pending: "bg-blue-100 text-blue-800",
      rejected: "bg-red-100 text-red-800",
      imported: "bg-purple-100 text-purple-800"
    };
    return statusColors[status] || "bg-gray-100 text-gray-800";
  };

  const CompanyCard = ({ company }) => {
    const isImported = company.status === 'imported';
    
    return (
      <div 
        onClick={() => navigate(`/companies/${company._id}`)}
        className="bg-white p-6 rounded-lg border border-gray-200 hover:shadow-lg transition-shadow duration-200 cursor-pointer"
      >
        <div className="flex justify-between items-start">
          <div className="space-y-1">
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10 rounded-full bg-indigo-50 flex items-center justify-center">
                <Building2 className="w-5 h-5 text-indigo-600" />
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  {company.companyNames?.first?.english || "Unnamed Company"}
                </h3>
                <p className="text-sm text-gray-500">
                  {company.companyDetails?.typeOfCompany || 'Free Zone Company'}
                  {company.companyDetails?.location && ` • ${company.companyDetails.location}`}
                </p>
              </div>
            </div>
          </div>
          <span className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(company.status)}`}>
            {company.status.charAt(0).toUpperCase() + company.status.slice(1)}
          </span>
        </div>

        {isImported ? (
          // Imported Company Details
          <div className="mt-4 space-y-4">
            {(company.companyDetails?.companyEmail || company.companyDetails?.companyMobile) && (
              <div className="grid grid-cols-2 gap-4">
                {company.companyDetails?.companyEmail && (
                  <div className="flex items-center space-x-2">
                    <Mail className="w-4 h-4 text-gray-400" />
                    <span className="text-sm text-gray-900">{company.companyDetails.companyEmail}</span>
                  </div>
                )}
                {company.companyDetails?.companyMobile && (
                  <div className="flex items-center space-x-2">
                    <Phone className="w-4 h-4 text-gray-400" />
                    <span className="text-sm text-gray-900">{company.companyDetails.companyMobile}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          // Regular Company Details
          <>
            <div className="mt-4 grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-medium text-gray-500">License Type</p>
                <p className="text-sm text-gray-900">{company.licenseType?.name || 'N/A'}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">Trade Zone</p>
                <p className="text-sm text-gray-900">{company.companyDetails?.typeOfTradeZone || 'N/A'}</p>
              </div>
            </div>

            {company.applicationSteps && (
              <div className="mt-4">
                <div className="flex items-center justify-between text-sm">
                  <span className="text-gray-500">Application Progress</span>
                </div>
                <div className="mt-2 w-full bg-gray-200 rounded-full h-2">
                  <div
                    className="bg-indigo-600 h-2 rounded-full"
                    style={{
                      width: `${(company.applicationSteps.filter(step => step.status === 'approved').length / 
                        company.applicationSteps.length) * 100}%`
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}

        <div className="mt-4 flex items-center justify-between text-xs text-gray-500">
          <div className="flex items-center">
            <Calendar className="w-4 h-4 mr-1" />
            {new Date(company.dateCreated).toLocaleDateString()}
          </div>
          {!isImported && company.companyType && (
            <div className="flex items-center">
              <span className="flex items-center">
                <BriefcaseIcon className="w-4 h-4 mr-1" />
                {company.companyType}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-medium text-gray-900">Companies</h3>
          <p className="text-sm text-gray-500 mt-1">
            {companies.length} {companies.length === 1 ? 'company' : 'companies'} found
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4">
        {companies.map((company) => (
          <CompanyCard key={company._id} company={company} />
        ))}
        {companies.length === 0 && (
          <div className="text-center py-8 bg-gray-50 rounded-lg">
            <Building2 className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No companies</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by creating a new company</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompaniesTab;