import { useState } from "react";

export const ServiceStatusBadge = ({ status }) => {
  const getStatusStyle = (status) => {
    const styles = {
      processing: "bg-yellow-100 text-yellow-800",
      "out for delivery": "bg-blue-100 text-blue-800",
      completed: "bg-green-100 text-green-800",
      cancelled: "bg-red-100 text-red-800",
    };
    return styles[status] || "bg-gray-100 text-gray-800";
  };

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(
        status
      )}`}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

export const ServiceStatusUpdate = ({ service, onStatusUpdate }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const statusSteps = [
    {
      value: "processing",
      label: "Processing",
      description: "Service request is being processed",
      icon: "⚙️",
      allowedTransitions: ["out for delivery", "completed", "cancelled"]
    },
    {
      value: "out for delivery",
      label: "Out for Delivery",
      description: "Service is being delivered",
      icon: "🚚",
      allowedTransitions: ["completed", "processing", "cancelled"]
    },
    {
      value: "completed",
      label: "Completed",
      description: "Service has been completed",
      icon: "✅",
      allowedTransitions: ["processing", "out for delivery"] // Allow reopening if needed
    },
    {
      value: "cancelled",
      label: "Cancelled",
      description: "Service has been cancelled",
      icon: "❌",
      allowedTransitions: ["processing"] // Allow reactivating cancelled services
    },
  ];

  const updateStatus = async (newStatus) => {
    setIsUpdating(true);
    try {
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/companies/${service.companyId}/services/${service._id}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ status: newStatus }),
        }
      );

      if (!response.ok) throw new Error("Failed to update service status");
      await onStatusUpdate();
    } catch (error) {
      console.error("Error updating service status:", error);
    } finally {
      setIsUpdating(false);
      setConfirmationOpen(false);
    }
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setConfirmationOpen(true);
  };

  const isStatusAllowed = (statusValue) => {
    const currentStep = statusSteps.find(s => s.value === service.status);
    // Always allow changing to any status if current status is unknown
    if (!currentStep) return true;
    
    // Special handling for cancelled and completed statuses
    if (service.status === "cancelled") {
      // Only allow moving back to processing from cancelled
      return statusValue === "processing";
    }
    
    return currentStep.allowedTransitions.includes(statusValue);
  };

  const StatusButton = ({ status, currentStatus }) => {
    const step = statusSteps.find((s) => s.value === status);
    const isCurrentStatus = status === currentStatus;
    const isAllowed = isStatusAllowed(status);

    return (
      <button
        onClick={() => handleStatusClick(status)}
        disabled={isUpdating || isCurrentStatus || !isAllowed}
        className={`
          relative w-full p-4 text-left border rounded-lg transition-colors
          ${
            isCurrentStatus
              ? "border-indigo-600 bg-indigo-50"
              : !isAllowed
              ? "border-gray-200 bg-gray-50 opacity-50 cursor-not-allowed"
              : "border-gray-200 hover:border-indigo-300 hover:bg-indigo-50"
          }
        `}
      >
        <div className="flex items-center">
          <span className="text-xl mr-3">{step.icon}</span>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-900">{step.label}</p>
            <p className="text-sm text-gray-500">{step.description}</p>
          </div>
          {isCurrentStatus && (
            <span className="h-2.5 w-2.5 rounded-full bg-indigo-600"></span>
          )}
        </div>
        {isUpdating && selectedStatus === status && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 rounded-lg">
            <div className="animate-spin h-5 w-5 border-2 border-indigo-600 border-t-transparent rounded-full"></div>
          </div>
        )}
      </button>
    );
  };

  const ConfirmationDialog = () => {
    if (!confirmationOpen || !selectedStatus) return null;

    const step = statusSteps.find((s) => s.value === selectedStatus);
    const isReverting = ["completed", "cancelled"].includes(service.status);

    return (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Update Service Status
          </h3>
          <p className="text-sm text-gray-500 mb-4">
            {isReverting 
              ? `Are you sure you want to revert this ${service.status} service to "${step.label}"?`
              : `Are you sure you want to mark this service as "${step.label}"?`}
          </p>
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setConfirmationOpen(false)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={() => updateStatus(selectedStatus)}
              className={`px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md ${
                isReverting
                  ? "bg-yellow-600 hover:bg-yellow-700"
                  : "bg-indigo-600 hover:bg-indigo-700"
              }`}
            >
              {isReverting ? "Yes, Revert Status" : "Confirm"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="border-b border-gray-200 pb-4">
        <h3 className="text-lg font-medium text-gray-900">Service Status</h3>
        <p className="mt-1 text-sm text-gray-500">
          Update the status of this service request
        </p>
      </div>

      <div className="mt-6">
        <div className="grid gap-4">
          {statusSteps.map((step) => (
            <StatusButton
              key={step.value}
              status={step.value}
              currentStatus={service.status}
            />
          ))}
        </div>
      </div>

      <ConfirmationDialog />
    </div>
  );
};