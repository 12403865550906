import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Building2,
  Calendar,
  FileText,
  Download,
  ArrowLeft,
  Stamp,
  Receipt,
  Building,
  BriefcaseIcon,
  User,
  Mail,
  Phone,
  Clock,
} from "lucide-react";
import Header from "../../Header";
import { ServiceStatusBadge, ServiceStatusUpdate } from "./ServiceStatusUpdate";

const ServiceDetailView = () => {
  const { id, serviceId } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "",
  });

  const fetchService = async () => {
    try {
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/companies/${id}/services/${serviceId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch service");
      const data = await response.json();
      setService(data);
    } catch (error) {
      console.error("Error fetching service:", error);
      setNotification({
        show: true,
        message: "Failed to load service details",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchService();
  }, [id, serviceId]);

  const handleStatusUpdate = async () => {
    await fetchService();
    setNotification({
      show: true,
      message: "Service status updated successfully",
      type: "success",
    });
    setTimeout(() => setNotification({ show: false, message: "", type: "" }), 3000);
  };

  const getServiceIcon = (serviceType) => {
    switch (serviceType) {
      case "Company Stamp":
        return Stamp;
      case "Corporate Tax":
        return Receipt;
      case "Bank Account Opening":
        return Building;
      default:
        return BriefcaseIcon;
    }
  };

  const renderServiceSpecificDetails = () => {
    if (!service) return null;

    switch (service.serviceType) {
      case "Company Stamp":
        return (
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h4 className="text-sm font-medium text-gray-500">Emirate</h4>
                <p className="mt-1 text-sm text-gray-900">{service.emirate}</p>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-500">Delivery Method</h4>
                <p className="mt-1 text-sm text-gray-900 capitalize">{service.deliveryMethod}</p>
              </div>
            </div>
            {service.deliveryMethod === "delivery" && (
              <div>
                <h4 className="text-sm font-medium text-gray-500">Delivery Address</h4>
                <p className="mt-1 text-sm text-gray-900 whitespace-pre-line">
                  {service.deliveryAddress}
                </p>
              </div>
            )}
          </div>
        );

      case "Corporate Tax":
        return (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="text-sm font-medium text-gray-500">Revenue Threshold</h4>
              <p className="mt-1 text-sm text-gray-900">
                {service.revenueThreshold === "true" ? "Yes" : "No"}
              </p>
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-500">UAE Residence Visa</h4>
              <p className="mt-1 text-sm text-gray-900">
                {service.hasResidenceVisa === "true" ? "Yes" : "No"}
              </p>
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-500">Exclusions</h4>
              <p className="mt-1 text-sm text-gray-900">
                {service.exclusions === "true" ? "Yes" : "No"}
              </p>
            </div>
          </div>
        );

      case "Bank Account Opening":
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h4 className="text-sm font-medium text-gray-500">Bank Plan</h4>
                <p className="mt-1 text-sm text-gray-900">{service.bankPlan}</p>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-500">Account Type</h4>
                <p className="mt-1 text-sm text-gray-900">{service.accountType}</p>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-500">PO Box</h4>
                <p className="mt-1 text-sm text-gray-900">{service.poBox || "Not provided"}</p>
              </div>
            </div>

            {service.shareholders && service.shareholders.length > 0 && (
              <div>
                <h4 className="text-base font-medium text-gray-900 mb-3">Shareholders</h4>
                <div className="space-y-4">
                  {service.shareholders.map((shareholder, index) => (
                    <div key={index} className="p-4 bg-gray-50 rounded-lg">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <h5 className="text-sm font-medium text-gray-500">Name</h5>
                          <p className="text-sm text-gray-900">{shareholder.name}</p>
                        </div>
                        <div>
                          <h5 className="text-sm font-medium text-gray-500">Email</h5>
                          <p className="text-sm text-gray-900">{shareholder.email}</p>
                        </div>
                        <div>
                          <h5 className="text-sm font-medium text-gray-500">Mobile</h5>
                          <p className="text-sm text-gray-900">{shareholder.mobile}</p>
                        </div>
                        <div>
                          <h5 className="text-sm font-medium text-gray-500">Address</h5>
                          <p className="text-sm text-gray-900">{shareholder.address}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  const renderRequesterInfo = () => {
    if (!service?.userDetails) return null;

    return (
      <div className="bg-white shadow rounded-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Requester Information</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex items-center space-x-3">
            <User className="h-5 w-5 text-gray-400" />
            <div>
              <p className="text-sm font-medium text-gray-500">Name</p>
              <p className="text-sm text-gray-900">
                {service.userDetails.first_name} {service.userDetails.last_name}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-3">
            <Mail className="h-5 w-5 text-gray-400" />
            <div>
              <p className="text-sm font-medium text-gray-500">Email</p>
              <p className="text-sm text-gray-900">{service.userDetails.email}</p>
            </div>
          </div>
          {service.userDetails.phone_number && (
            <div className="flex items-center space-x-3">
              <Phone className="h-5 w-5 text-gray-400" />
              <div>
                <p className="text-sm font-medium text-gray-500">Phone</p>
                <p className="text-sm text-gray-900">{service.userDetails.phone_number}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderDocuments = () => {
    if (!service?.documents || Object.keys(service.documents).length === 0) {
      return (
        <div className="text-center py-12">
          <FileText className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No documents</h3>
          <p className="mt-1 text-sm text-gray-500">
            No documents have been uploaded for this service
          </p>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 gap-4">
        {Object.entries(service.documents).map(([key, url]) => {
          const docName = key
            .replace(/\[\d+\]/g, "")
            .replace(/\[|\]/g, "")
            .split(/(?=[A-Z])/)
            .join(" ")
            .replace(/^\w/, (c) => c.toUpperCase());

          return (
            <a
              key={key}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center p-4 border rounded-lg hover:bg-gray-50 transition-colors"
            >
              <FileText className="h-6 w-6 text-gray-400" />
              <span className="ml-3 flex-1 text-sm text-gray-900">{docName}</span>
              <Download className="h-5 w-5 text-gray-400" />
            </a>
          );
        })}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-50">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  const ServiceIcon = service ? getServiceIcon(service.serviceType) : BriefcaseIcon;

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Back button and notification */}
        <div className="space-y-4 mb-6">
          <button
            onClick={() => navigate(`/companies/${id}`)}
            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Company
          </button>

          {notification.show && (
            <div
              className={`p-4 rounded-md ${
                notification.type === "success"
                  ? "bg-green-50 text-green-800"
                  : "bg-red-50 text-red-800"
              }`}
            >
              {notification.message}
            </div>
          )}
        </div>

        <div className="space-y-6">
          {/* Service Overview Card */}
          <div className="bg-white shadow rounded-lg p-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className="h-12 w-12 rounded-full bg-indigo-100 flex items-center justify-center">
                  <ServiceIcon className="h-6 w-6 text-indigo-600" />
                </div>
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    {service?.serviceType}
                  </h1>
                  <div className="flex items-center mt-1 space-x-4">
                    <div className="flex items-center text-sm text-gray-500">
                      <Clock className="w-4 h-4 mr-1" />
                      Requested on {new Date(service?.requestDate).toLocaleDateString()}
                    </div>
                    <ServiceStatusBadge status={service?.status} />
                    {service?.paymentStatus && (
                      <span
                        className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          service.paymentStatus === "complete"
                            ? "bg-green-100 text-green-800"
                            : "bg-yellow-100 text-yellow-800"
                        }`}
                      >
                        {service.paymentStatus}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Service Status Update */}
          <ServiceStatusUpdate service={service} onStatusUpdate={handleStatusUpdate} />

          {/* Main Content Grid */}
          <div className="grid grid-cols-1 gap-6">
            {/* Service Details Card */}
            <div className="bg-white shadow rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Service Details
              </h3>
              {renderServiceSpecificDetails()}
            </div>

            {/* Requester Information */}
            {renderRequesterInfo()}

            {/* Documents Section */}
            <div className="bg-white shadow rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Documents
              </h3>
              {renderDocuments()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailView;