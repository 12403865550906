import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Building2,
  Mail,
  Phone,
  MapPin,
  Edit,
  Trash2,
  ArrowLeft,
  Clock,
  Calendar,
  CheckCircle2,
  XCircle,
  FileText,
  Users,
  MessageSquare,
  Plus,
  ChevronRight,
  Download,
  AlertCircle,
  Table,
  BriefcaseIcon,
  Stamp,
  Receipt,
  Building,
} from "lucide-react";
import Header from "../../Header";
import ApplicationSteps, { DEFAULT_STEPS } from "./ApplicationSteps";
import KYCStatusSection from "./KYCStatusSection";

const CompanyDetailView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "",
  });
  const [uploading, setUploading] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState("OTHER");
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [services, setServices] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(false);

  // Add this new function to fetch services
  const fetchServices = async () => {
    try {
      setServicesLoading(true);
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/companies/${id}/services`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch services");
      const data = await response.json();
      setServices(data.services);
    } catch (error) {
      console.error("Error fetching services:", error);
      setNotification({
        show: true,
        message: "Failed to load services",
        type: "error",
      });
    } finally {
      setServicesLoading(false);
    }
  };

  const [activeTab, setActiveTab] = useState("overview");
  const fetchCompany = async () => {
    try {
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/companies/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch company");
      const data = await response.json();
      setCompany(data);
    } catch (error) {
      console.error("Error fetching company:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "services") {
      fetchServices();
    }
  }, [activeTab, id]);

  useEffect(() => {
    fetchCompany();
  }, [id]);

  const DOCUMENT_TYPES = {
    PASSPORT: {
      label: "Passport Copy",
      description: "Clear copy of passport",
    },
    VISA: {
      label: "Visa Copy",
      description: "Valid UAE visa copy",
    },
    EMIRATES_ID: {
      label: "Emirates ID",
      description: "Front and back of Emirates ID",
    },
    OTHER: {
      label: "Other Documents",
      description: "Any additional documents",
    },
  };

  const handleDocumentUpload = async (file, documentType = "OTHER") => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentType", documentType);

    try {
      setUploading(true);
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/companies/${id}/documents`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to upload document");
      }

      setNotification({
        show: true,
        message: "Document uploaded successfully",
        type: "success",
      });

      fetchCompany();
    } catch (error) {
      console.error("Error uploading document:", error);
      setNotification({
        show: true,
        message: error.message || "Failed to upload document",
        type: "error",
      });
    } finally {
      setUploading(false);
    }
  };

  const handleDocumentDelete = async (fileUrl, filename) => {
    // Show confirmation dialog
    if (
      !window.confirm(
        `Are you sure you want to delete "${filename}"? This action cannot be undone.`
      )
    ) {
      return;
    }
  
    try {
      // Ensure fileUrl is a string - if it's accidentally an object, extract the fileUrl property
      const urlToDelete = typeof fileUrl === 'object' && fileUrl.fileUrl 
        ? fileUrl.fileUrl 
        : fileUrl;
      
      // Encode the URL to ensure it's properly transmitted in the request
      const encodedFileUrl = encodeURIComponent(urlToDelete);
      
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/companies/${id}/documents/${encodedFileUrl}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to delete document");
      }
  
      setNotification({
        show: true,
        message: "Document deleted successfully",
        type: "success",
      });
  
      // Refresh company data to get updated documents list
      fetchCompany();
    } catch (error) {
      console.error("Error deleting document:", error);
      setNotification({
        show: true,
        message: error.message || "Failed to delete document",
        type: "error",
      });
    }
  };

  const handleStepUpdate = async (stepKey, status) => {
    try {
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/companies/${id}/steps/${stepKey}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ status }),
        }
      );

      if (!response.ok) throw new Error("Failed to update step");

      // Refresh company data to get updated steps
      fetchCompany();
    } catch (error) {
      console.error("Error updating step:", error);
    }
  };

  const getStatusColor = (status) => {
    const statusColors = {
      processing: "bg-yellow-100 text-yellow-800",
      approved: "bg-green-100 text-green-800",
      pending: "bg-blue-100 text-blue-800",
      rejected: "bg-red-100 text-red-800",
      imported: "bg-purple-100 text-purple-800",
    };
    return statusColors[status] || "bg-gray-100 text-gray-800";
  };

  const formatDocumentName = (key) => {
    return key
      .replace(/Url$/, "")
      .split(/(?=[A-Z])/)
      .join(" ")
      .replace(/^\w/, (c) => c.toUpperCase());
  };

  const normalizeDocuments = (company) => {
    if (company.status !== "imported") {
      return company.documents || [];
    }

    return Object.entries(company.documents || {}).map(([key, url]) => ({
      documentType: "IMPORTED",
      originalName: formatDocumentName(key),
      fileUrl: url,
      uploadDate: company.dateCreated,
    }));
  };

  const TabButton = ({ tab, icon: Icon, label, count }) => (
    <button
      onClick={() => setActiveTab(tab)}
      className={`flex items-center px-4 py-2 text-sm font-medium rounded-lg ${
        activeTab === tab
          ? "bg-indigo-50 text-indigo-700"
          : "text-gray-600 hover:bg-gray-50"
      }`}
    >
      <Icon className="w-5 h-5 mr-2" />
      {label}
      {count !== undefined && count > 0 && (
        <span className="ml-2 bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full text-xs">
          {count}
        </span>
      )}
    </button>
  );

  const ActivityItem = ({ icon: Icon, title, description, date }) => (
    <div className="flex space-x-3 mb-4">
      <div className="flex-shrink-0">
        <div className="w-8 h-8 rounded-full bg-indigo-50 flex items-center justify-center">
          <Icon className="w-4 h-4 text-indigo-600" />
        </div>
      </div>
      <div className="flex-grow">
        <p className="text-sm font-medium text-gray-900">{title}</p>
        <p className="text-sm text-gray-500">{description}</p>
        <p className="text-xs text-gray-400 mt-1">
          {new Date(date).toLocaleDateString()}
        </p>
      </div>
    </div>
  );

  const renderDocumentsSection = () => {
    const documents = normalizeDocuments(company);

    return (
      <div className="space-y-6">
        {notification.show && (
          <div
            className={`p-4 rounded-md ${
              notification.type === "success"
                ? "bg-green-50 text-green-800"
                : "bg-red-50 text-red-800"
            }`}
          >
            {notification.message}
          </div>
        )}

        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium text-gray-900">Documents</h3>
          <button
            onClick={() => setShowUploadForm(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="w-4 h-4 mr-2" />
            Upload Document
          </button>
        </div>

        {showUploadForm && (
          <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
            <div className="flex items-end space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Document Type
                </label>
                <select
                  value={selectedDocumentType}
                  onChange={(e) => setSelectedDocumentType(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  {Object.entries(DOCUMENT_TYPES).map(([key, { label }]) => (
                    <option key={key} value={key}>
                      {label}
                    </option>
                  ))}
                </select>
                <p className="mt-1 text-xs text-gray-500">
                  {DOCUMENT_TYPES[selectedDocumentType].description}
                </p>
              </div>
              <div className="flex-1">
                <input
                  id="fileUpload"
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    handleDocumentUpload(
                      e.target.files[0],
                      selectedDocumentType
                    );
                    setShowUploadForm(false);
                  }}
                  disabled={uploading}
                />
                <button
                  onClick={() => document.getElementById("fileUpload").click()}
                  disabled={uploading}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-400"
                >
                  {uploading ? (
                    <>
                      <div className="animate-spin h-4 w-4 mr-2 border-2 border-white border-t-transparent rounded-full" />
                      Uploading...
                    </>
                  ) : (
                    "Choose File"
                  )}
                </button>
                <button
                  onClick={() => setShowUploadForm(false)}
                  className="ml-2 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="bg-white shadow overflow-hidden rounded-md">
          {!documents?.length ? (
            <div className="text-center py-12">
              <FileText className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No documents
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                No documents available
              </p>
            </div>
          ) : (
            <ul className="divide-y divide-gray-200">
              {documents.map((doc, index) => (
                <li key={index} className="p-4 hover:bg-gray-50">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center min-w-0">
                      <FileText className="h-8 w-8 text-gray-400" />
                      <div className="ml-4">
                        <p className="font-medium text-gray-900 truncate">
                          {doc.originalName}
                        </p>
                        <div className="flex items-center mt-1">
                          <span className="text-sm text-gray-500">
                            {new Date(doc.uploadDate).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <a
                        href={doc.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-2 text-gray-400 hover:text-gray-500"
                      >
                        <Download className="w-5 h-5" />
                      </a>

                      <button
                        onClick={() =>
                          handleDocumentDelete(doc.fileUrl, doc.originalName)
                        }
                        className="p-2 text-red-400 hover:text-red-500"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  };

  const renderOverviewContent = () => {
    const isImported = company?.status === "imported";

    return (
      <div className="space-y-6">
        {/* Basic Information */}
        <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
          <div className="border-b border-gray-200 px-6 py-4">
            <h3 className="text-lg font-medium text-gray-900">
              Company Information
            </h3>
          </div>
          <div className="px-6 py-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex items-center space-x-3">
                <Building2 className="h-5 w-5 text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Company Type
                  </p>
                  <p className="text-sm text-gray-900">
                    {company?.companyDetails?.typeOfCompany}
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-3">
                <Mail className="h-5 w-5 text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Email</p>
                  <p className="text-sm text-gray-900">
                    {company?.email}
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-3">
                <Phone className="h-5 w-5 text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Mobile</p>
                  <p className="text-sm text-gray-900">
                    {company?.phone}
                  </p>
                </div>
              </div>

              {!isImported && (
                <>
                  <div className="flex items-center space-x-3">
                    <MapPin className="h-5 w-5 text-gray-400" />
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        Jurisdiction
                      </p>
                      <p className="text-sm text-gray-900">
                        {company?.companyDetails?.typeOfTradeZone} -{" "}
                        {company?.companyDetails?.location}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center space-x-3">
                    <Clock className="h-5 w-5 text-gray-400" />
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        Timeline
                      </p>
                      <p className="text-sm text-gray-900">
                        {company?.companyDetails?.timeline}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Company Names */}
        <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
          <div className="border-b border-gray-200 px-6 py-4">
            <h3 className="text-lg font-medium text-gray-900">Company Names</h3>
          </div>
          <div className="px-6 py-4">
            <div className="space-y-4">
              <div className="flex items-center justify-between p-4 border rounded-lg">
                <div className="space-y-2">
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      English Name
                    </p>
                    <p className="font-medium text-gray-900">
                      {company?.companyNames?.first?.english}
                    </p>
                  </div>
                  {company?.companyNames?.first?.arabic && (
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        Arabic Name
                      </p>
                      <p className="font-medium text-gray-900 text-right">
                        {company?.companyNames?.first?.arabic}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isImported && (
          <>
            <div className="space-y-6">
              <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                <div className="border-b border-gray-200 px-6 py-4">
                  <h3 className="text-lg font-medium text-gray-900">
                    Company Information
                  </h3>
                </div>
                <div className="px-6 py-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="flex items-center space-x-3">
                      <Building2 className="h-5 w-5 text-gray-400" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Package
                        </p>
                        <p className="text-sm text-gray-900">
                          {`${
                            company?.package?.multiyear || 1
                          } Year License - AED ${company?.package?.licenseFee}`}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <Building2 className="h-5 w-5 text-gray-400" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Type
                        </p>
                        <p className="text-sm text-gray-900">
                          {company?.companyDetails?.typeOfCompany} •{" "}
                          {company?.typeOfOffice}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <MapPin className="h-5 w-5 text-gray-400" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Jurisdiction
                        </p>
                        <p className="text-sm text-gray-900">
                          {company?.companyDetails?.typeOfTradeZone} -{" "}
                          {company?.companyDetails?.location}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <Clock className="h-5 w-5 text-gray-400" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Timeline
                        </p>
                        <p className="text-sm text-gray-900">
                          {company?.companyDetails?.timeline}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Company Activities */}
              <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                <div className="border-b border-gray-200 px-6 py-4">
                  <h3 className="text-lg font-medium text-gray-900">
                    Business Activities
                  </h3>
                </div>
                <div className="px-6 py-4">
                  <div className="space-y-4">
                    {company?.companyActivities?.map((activity, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between p-4 border rounded-lg"
                      >
                        <div>
                          <p className="font-medium text-gray-900">
                            {activity["Activity Name"]}
                          </p>
                          <p className="text-sm text-gray-500">
                            Code: {activity["Activity Code"]} •{" "}
                            {activity.Classification}
                          </p>
                        </div>
                        <span className="px-3 py-1 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                          {activity.Type}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                <div className="border-b border-gray-200 px-6 py-4">
                  <h3 className="text-lg font-medium text-gray-900">
                    Company Names
                  </h3>
                </div>
                <div className="px-6 py-4">
                  <div className="space-y-4">
                    {/* First Choice */}
                    {company?.companyNames?.first && (
                      <div className="flex items-center justify-between p-4 border rounded-lg">
                        <div className="space-y-2">
                          <div>
                            <p className="text-sm font-medium text-gray-500">
                              First Choice
                            </p>
                            <p className="font-medium text-gray-900">
                              {company.companyNames.first.english}
                            </p>
                            <p className="font-medium text-gray-900 text-right">
                              {company.companyNames.first.arabic}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Second Choice */}
                    {company?.companyNames?.second && (
                      <div className="flex items-center justify-between p-4 border rounded-lg">
                        <div className="space-y-2">
                          <div>
                            <p className="text-sm font-medium text-gray-500">
                              Second Choice
                            </p>
                            <p className="font-medium text-gray-900">
                              {company.companyNames.second.english}
                            </p>
                            <p className="font-medium text-gray-900 text-right">
                              {company.companyNames.second.arabic}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Third Choice */}
                    {company?.companyNames?.third && (
                      <div className="flex items-center justify-between p-4 border rounded-lg">
                        <div className="space-y-2">
                          <div>
                            <p className="text-sm font-medium text-gray-500">
                              Third Choice
                            </p>
                            <p className="font-medium text-gray-900">
                              {company.companyNames.third.english}
                            </p>
                            <p className="font-medium text-gray-900 text-right">
                              {company.companyNames.third.arabic}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Shareholders Section */}
              <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                <div className="border-b border-gray-200 px-6 py-4">
                  <h3 className="text-lg font-medium text-gray-900">
                    Share Capital & Management
                  </h3>
                </div>
                <div className="px-6 py-4 space-y-8">
                  {/* Share Capital Details */}
                  <div>
                    <h4 className="text-base font-medium text-gray-900 mb-4">
                      Share Capital
                    </h4>
                    <div className="grid grid-cols-3 gap-4">
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Total Value
                        </p>
                        <p className="text-sm text-gray-900">
                          AED{" "}
                          {company?.shareholderDetails?.shareCapital?.totalValue?.toLocaleString()}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Number of Shares
                        </p>
                        <p className="text-sm text-gray-900">
                          {company?.shareholderDetails?.shareCapital?.numberOfShares?.toLocaleString()}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Value per Share
                        </p>
                        <p className="text-sm text-gray-900">
                          AED{" "}
                          {company?.shareholderDetails?.shareCapital?.valuePerShare?.toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Shareholders List */}
                  <div>
                    <h4 className="text-base font-medium text-gray-900 mb-4">
                      Shareholders
                    </h4>
                    <div className="space-y-4">
                      {company?.shareholderDetails?.shareholders?.map(
                        (shareholder, index) => (
                          <div
                            key={index}
                            className="p-4 border rounded-lg space-y-4"
                          >
                            {/* Shareholder Info Section */}
                            <div className="flex justify-between items-start">
                              <div className="space-y-4 flex-1">
                                <div className="flex justify-between items-start">
                                  <div>
                                    <p className="font-medium text-gray-900">
                                      {shareholder.name}
                                    </p>
                                    <p className="text-sm text-gray-500 mt-1">
                                      Father's Name: {shareholder.fatherName}
                                    </p>
                                  </div>
                                  <span
                                    className={`px-3 py-1 rounded-full text-xs font-medium ${
                                      shareholder.isUAEresident
                                        ? "bg-green-100 text-green-800"
                                        : "bg-blue-100 text-blue-800"
                                    }`}
                                  >
                                    {shareholder.isUAEresident
                                      ? "UAE Resident"
                                      : "Non-Resident"}
                                  </span>
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                  <div>
                                    <p className="text-sm text-gray-500">
                                      Nationality
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      {shareholder.nationality}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-500">
                                      Shares
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      {shareholder.shares}%
                                    </p>
                                  </div>
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                  <div>
                                    <p className="text-sm text-gray-500">
                                      Email
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      {shareholder.email}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-500">
                                      Mobile
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      {shareholder.mobile}
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <p className="text-sm text-gray-500">
                                    Address
                                  </p>
                                  <p className="text-sm text-gray-900">
                                    {shareholder.addressLine1}
                                    {shareholder.addressLine2 &&
                                      `, ${shareholder.addressLine2}`}
                                  </p>
                                  <p className="text-sm text-gray-900">
                                    {shareholder.cityState},{" "}
                                    {shareholder.country}{" "}
                                    {shareholder.postalCode}
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* KYC Status Section */}
                            {shareholder.onfidoWorkflowId && (
                              <div className="border-t pt-4">
                                <h4 className="text-sm font-medium text-gray-900 mb-2">
                                  KYC Verification Status
                                </h4>
                                <KYCStatusSection
                                  shareholder={shareholder}
                                  companyId={id}
                                />
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  {/* Secretary Details */}
                  {company?.shareholderDetails?.secretary && (
                    <div>
                      <h4 className="text-base font-medium text-gray-900 mb-4">
                        Company Secretary
                      </h4>
                      <div className="p-4 border rounded-lg">
                        <div className="space-y-2">
                          <div>
                            <p className="font-medium text-gray-900">
                              {company.shareholderDetails.secretary.name}
                            </p>
                            <p className="text-sm text-gray-500 mt-1">
                              Father's Name:{" "}
                              {company.shareholderDetails.secretary.fatherName}
                            </p>
                          </div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <p className="text-sm text-gray-500">
                                Nationality
                              </p>
                              <p className="text-sm text-gray-900">
                                {
                                  company.shareholderDetails.secretary
                                    .nationality
                                }
                              </p>
                            </div>
                            <div>
                              <p className="text-sm text-gray-500">Contact</p>
                              <p className="text-sm text-gray-900">
                                {company.shareholderDetails.secretary.email}
                              </p>
                              <p className="text-sm text-gray-900">
                                {company.shareholderDetails.secretary.mobile}
                              </p>
                            </div>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500">Address</p>
                            <p className="text-sm text-gray-900">
                              {
                                company.shareholderDetails.secretary
                                  .addressLine1
                              }
                              {company.shareholderDetails.secretary
                                .addressLine2 &&
                                `, ${company.shareholderDetails.secretary.addressLine2}`}
                            </p>
                            <p className="text-sm text-gray-900">
                              {company.shareholderDetails.secretary.cityState},{" "}
                              {company.shareholderDetails.secretary.country}{" "}
                              {company.shareholderDetails.secretary.postalCode}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* General Manager Details */}
                  {company?.shareholderDetails?.generalManager && (
                    <div>
                      <h4 className="text-base font-medium text-gray-900 mb-4">
                        General Manager
                      </h4>
                      <div className="p-4 border rounded-lg">
                        <div className="space-y-2">
                          <div>
                            <p className="font-medium text-gray-900">
                              {company.shareholderDetails.generalManager.name}
                            </p>
                            <p className="text-sm text-gray-500 mt-1">
                              Father's Name:{" "}
                              {
                                company.shareholderDetails.generalManager
                                  .fatherName
                              }
                            </p>
                          </div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <p className="text-sm text-gray-500">
                                Nationality
                              </p>
                              <p className="text-sm text-gray-900">
                                {
                                  company.shareholderDetails.generalManager
                                    .nationality
                                }
                              </p>
                            </div>
                            <div>
                              <p className="text-sm text-gray-500">Contact</p>
                              <p className="text-sm text-gray-900">
                                {
                                  company.shareholderDetails.generalManager
                                    .email
                                }
                              </p>
                              <p className="text-sm text-gray-900">
                                {
                                  company.shareholderDetails.generalManager
                                    .mobile
                                }
                              </p>
                            </div>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500">Address</p>
                            <p className="text-sm text-gray-900">
                              {
                                company.shareholderDetails.generalManager
                                  .addressLine1
                              }
                              {company.shareholderDetails.generalManager
                                .addressLine2 &&
                                `, ${company.shareholderDetails.generalManager.addressLine2}`}
                            </p>
                            <p className="text-sm text-gray-900">
                              {
                                company.shareholderDetails.generalManager
                                  .cityState
                              }
                              ,{" "}
                              {
                                company.shareholderDetails.generalManager
                                  .country
                              }{" "}
                              {
                                company.shareholderDetails.generalManager
                                  .postalCode
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Directors List */}
                  {company?.shareholderDetails?.directors?.length > 0 && (
                    <div>
                      <h4 className="text-base font-medium text-gray-900 mb-4">
                        Directors
                      </h4>
                      <div className="space-y-4">
                        {company.shareholderDetails.directors.map(
                          (director, index) => (
                            <div key={index} className="p-4 border rounded-lg">
                              <div className="space-y-2">
                                <div>
                                  <p className="font-medium text-gray-900">
                                    {director.name}
                                  </p>
                                  <p className="text-sm text-gray-500 mt-1">
                                    Father's Name: {director.fatherName}
                                  </p>
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                  <div>
                                    <p className="text-sm text-gray-500">
                                      Nationality
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      {director.nationality}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-500">
                                      Contact
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      {director.email}
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      {director.mobile}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <p className="text-sm text-gray-500">
                                    Address
                                  </p>
                                  <p className="text-sm text-gray-900">
                                    {director.addressLine1}
                                    {director.addressLine2 &&
                                      `, ${director.addressLine2}`}
                                  </p>
                                  <p className="text-sm text-gray-900">
                                    {director.cityState}, {director.country}{" "}
                                    {director.postalCode}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* UBO Section */}
              <div className="bg-white rounded-lg border border-gray-200 shadow-sm mt-6">
                <div className="border-b border-gray-200 px-6 py-4">
                  <h3 className="text-lg font-medium text-gray-900">
                    Ultimate Beneficial Owner
                  </h3>
                </div>
                <div className="px-6 py-4">
                  <div className="space-y-4">
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        UBO Option
                      </p>
                      <p className="text-sm text-gray-900 mt-1">
                        {company?.ubo?.uboOption}
                      </p>
                    </div>

                    {company?.ubo?.uboMembersList?.length > 0 && (
                      <div className="mt-4">
                        <p className="text-sm font-medium text-gray-500 mb-3">
                          UBO Members
                        </p>
                        <div className="space-y-4">
                          {company?.ubo?.uboMembersList.map((member, index) => (
                            <div key={index} className="p-4 border rounded-lg">
                              <div className="space-y-2">
                                <div>
                                  <p className="font-medium text-gray-900">
                                    {member.name}
                                  </p>
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                  <div>
                                    <p className="text-sm text-gray-500">
                                      Nationality
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      {member.nationality}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-500">
                                      Ownership Percentage
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      {member.percentage}%
                                    </p>
                                  </div>
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                  <div>
                                    <p className="text-sm text-gray-500">
                                      Email
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      {member.email}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-500">
                                      Phone
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      {member.phone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Package Details Section */}
              <div className="bg-white rounded-lg border border-gray-200 shadow-sm mt-6">
                <div className="border-b border-gray-200 px-6 py-4">
                  <h3 className="text-lg font-medium text-gray-900">
                    Package Details
                  </h3>
                </div>
                <div className="px-6 py-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        License Fee
                      </p>
                      <p className="text-sm text-gray-900">
                        AED {company?.package?.licenseFee?.toLocaleString()}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        Establishment Card
                      </p>
                      <p className="text-sm text-gray-900">
                        AED{" "}
                        {company?.package?.establishmentCard?.toLocaleString()}
                      </p>
                    </div>
                    {company?.package?.discountFee && (
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Discounted License Fee
                        </p>
                        <p className="text-sm text-gray-900">
                          AED {company?.package?.discountFee?.toLocaleString()}
                        </p>
                      </div>
                    )}
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        Number of Visas
                      </p>
                      <p className="text-sm text-gray-900">
                        {company?.package?.visa}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        License Duration
                      </p>
                      <p className="text-sm text-gray-900">
                        {company?.package?.isMultiYear
                          ? `${company?.package?.multiyear} Years`
                          : "1 Year"}
                      </p>
                    </div>
                  </div>

                  {/* Add-ons Section */}
                  {company?.addons &&
                    Object.keys(company.addons).length > 0 && (
                      <div className="mt-6">
                        <h4 className="text-base font-medium text-gray-900 mb-4">
                          Add-ons
                        </h4>
                        <div className="space-y-4">
                          {Object.entries(company.addons).map(
                            ([key, addon]) =>
                              addon.selected && (
                                <div
                                  key={key}
                                  className="p-4 border rounded-lg"
                                >
                                  <div className="flex justify-between">
                                    <div>
                                      <p className="font-medium text-gray-900">
                                        {addon.package
                                          ?.replace(/([A-Z])/g, " $1")
                                          .replace(/^./, (str) =>
                                            str.toUpperCase()
                                          )}
                                      </p>
                                      <p className="text-sm text-gray-500 mt-1">
                                        {addon.mainService
                                          ?.replace(/([A-Z])/g, " $1")
                                          .replace(/^./, (str) =>
                                            str.toUpperCase()
                                          )}
                                      </p>
                                      {addon.timeline && (
                                        <p className="text-sm text-gray-500">
                                          Timeline: {addon.timeline}
                                        </p>
                                      )}
                                    </div>
                                    <div className="text-right">
                                      <p className="font-medium text-gray-900">
                                        AED {addon.cost?.toLocaleString()}
                                      </p>
                                      {addon.quantity > 1 && (
                                        <p className="text-sm text-gray-500">
                                          Quantity: {addon.quantity}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    )}
                </div>
              </div>

              {/* Application Progress */}
              <ApplicationSteps
                company={company}
                onStepUpdate={async (stepKey, status) => {
                  try {
                    await handleStepUpdate(stepKey, status);
                    // Refresh company data to get updated steps
                    await fetchCompany();
                  } catch (error) {
                    console.error("Error updating step:", error);
                    setNotification({
                      show: true,
                      message: "Failed to update step",
                      type: "error",
                    });
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  // Service type specific components
  const ServiceDetails = ({ service }) => {
    const renderServiceSpecificDetails = () => {
      switch (service.serviceType) {
        case "Company Stamp":
          return (
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
              <div>
                <span className="text-gray-500">Emirate:</span>
                <span className="ml-2 text-gray-900">{service.emirate}</span>
              </div>
              {service.deliveryMethod === "delivery" && (
                <div>
                  <span className="text-gray-500">Delivery Address:</span>
                  <p className="mt-1 text-gray-900 whitespace-pre-line">
                    {service.deliveryAddress}
                  </p>
                </div>
              )}
              {service.deliveryMethod === "pickup" &&
                service.pickupLocation && (
                  <div>
                    <span className="text-gray-500">Pickup Location:</span>
                    <p className="mt-1 text-gray-900">
                      {service.pickupLocation}
                    </p>
                  </div>
                )}
            </div>
          );

        case "Corporate Tax":
          return (
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
              <div>
                <span className="text-gray-500">Revenue Threshold:</span>
                <span className="ml-2 text-gray-900">
                  {service.revenueThreshold === "true" ? "Yes" : "No"}
                </span>
              </div>
              <div>
                <span className="text-gray-500">Has Residence Visa:</span>
                <span className="ml-2 text-gray-900">
                  {service.hasResidenceVisa === "true" ? "Yes" : "No"}
                </span>
              </div>
              <div>
                <span className="text-gray-500">Exclusions:</span>
                <span className="ml-2 text-gray-900">
                  {service.exclusions === "true" ? "Yes" : "No"}
                </span>
              </div>
            </div>
          );

        case "Bank Account Opening":
          return (
            <div className="mt-4 space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                <div>
                  <span className="text-gray-500">Bank Plan:</span>
                  <span className="ml-2 text-gray-900">{service.bankPlan}</span>
                </div>
                <div>
                  <span className="text-gray-500">Account Type:</span>
                  <span className="ml-2 text-gray-900">
                    {service.accountType}
                  </span>
                </div>
                <div>
                  <span className="text-gray-500">PO Box:</span>
                  <span className="ml-2 text-gray-900">{service.poBox}</span>
                </div>
              </div>

              {service.shareholders && service.shareholders.length > 0 && (
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-2">
                    Shareholders
                  </h4>
                  <div className="space-y-4">
                    {service.shareholders.map((shareholder, index) => (
                      <div key={index} className="bg-gray-50 rounded-lg p-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                          <div>
                            <span className="text-gray-500">Name:</span>
                            <span className="ml-2 text-gray-900">
                              {shareholder.name}
                            </span>
                          </div>
                          <div>
                            <span className="text-gray-500">Email:</span>
                            <span className="ml-2 text-gray-900">
                              {shareholder.email}
                            </span>
                          </div>
                          <div>
                            <span className="text-gray-500">Mobile:</span>
                            <span className="ml-2 text-gray-900">
                              {shareholder.mobile}
                            </span>
                          </div>
                          <div>
                            <span className="text-gray-500">Address:</span>
                            <p className="mt-1 text-gray-900">
                              {shareholder.address}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          );

        default:
          return null;
      }
    };

    return (
      <div className="space-y-4">
        {renderServiceSpecificDetails()}

        {/* Documents Section */}
        {service.documents && Object.keys(service.documents).length > 0 && (
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-900 mb-2">
              Documents
            </h4>
            <div className="grid grid-cols-1 gap-2">
              {Object.entries(service.documents).map(([key, url]) => {
                // Clean up document key names
                const docName = key
                  .replace(/\[\d+\]/g, "") // Remove array indices
                  .replace(/\[|\]/g, "") // Remove remaining brackets
                  .split(/(?=[A-Z])/)
                  .join(" ") // Split on capital letters
                  .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize first letter

                return (
                  <a
                    key={key}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center p-2 hover:bg-gray-50 rounded-md group"
                  >
                    <FileText className="h-4 w-4 text-gray-400 group-hover:text-gray-500" />
                    <span className="ml-2 text-sm text-gray-600 group-hover:text-gray-700">
                      {docName}
                    </span>
                    <Download className="h-4 w-4 ml-auto text-gray-400 group-hover:text-gray-500" />
                  </a>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderServicesSection = () => {
    const getServiceIcon = (serviceType) => {
      switch (serviceType) {
        case "Company Stamp":
          return Stamp;
        case "Corporate Tax":
          return Receipt;
        case "Bank Account Opening":
          return Building;
        default:
          return BriefcaseIcon;
      }
    };

    if (servicesLoading) {
      return (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
        </div>
      );
    }

    return (
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-lg font-medium text-gray-900">Services</h3>
            <p className="mt-1 text-sm text-gray-500">
              Manage and track your business services
            </p>
          </div>
        </div>

        {/* Services List */}
        <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-gray-200">
          {!services?.length ? (
            <div className="text-center py-16 px-4">
              <div className="mx-auto h-12 w-12 text-gray-400 bg-gray-50 rounded-lg flex items-center justify-center">
                <BriefcaseIcon className="h-6 w-6" />
              </div>
              <h3 className="mt-4 text-sm font-medium text-gray-900">
                No services
              </h3>
              <p className="mt-2 text-sm text-gray-500 max-w-sm mx-auto">
                No services have been requested yet. Services will appear here
                once requested.
              </p>
            </div>
          ) : (
            <ul className="divide-y divide-gray-200">
              {services.map((service, index) => {
                const ServiceIcon = getServiceIcon(service.serviceType);

                return (
                  <li
                    key={index}
                    onClick={() =>
                      navigate(`/companies/${id}/services/${service._id}`)
                    }
                    className="group relative hover:bg-gray-50 transition-all duration-200 cursor-pointer"
                  >
                    <div className="p-6">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          <div className="h-12 w-12 rounded-lg bg-indigo-50 group-hover:bg-indigo-100 transition-colors flex items-center justify-center">
                            <ServiceIcon className="h-6 w-6 text-indigo-600" />
                          </div>
                        </div>
                        <div className="ml-4 flex-1 min-w-0">
                          <div className="flex items-center justify-between">
                            <div>
                              <h4 className="text-base font-medium text-gray-900 group-hover:text-indigo-600 transition-colors">
                                {service.serviceType}
                              </h4>
                              <div className="mt-1 flex flex-wrap items-center gap-2 text-sm text-gray-500">
                                <div className="flex items-center">
                                  <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                                  {new Date(
                                    service.requestDate
                                  ).toLocaleDateString()}
                                </div>

                                {/* Service specific preview info */}
                                {service.serviceType === "Company Stamp" && (
                                  <div className="flex items-center">
                                    <span className="mx-2 text-gray-300">
                                      •
                                    </span>
                                    <MapPin className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                                    {service.emirate}
                                  </div>
                                )}

                                {service.serviceType ===
                                  "Bank Account Opening" && (
                                  <div className="flex items-center">
                                    <span className="mx-2 text-gray-300">
                                      •
                                    </span>
                                    <Building className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                                    {service.bankPlan} Plan
                                  </div>
                                )}

                                {/* Document count */}
                                {service.documents &&
                                  Object.keys(service.documents).length > 0 && (
                                    <div className="flex items-center">
                                      <span className="mx-2 text-gray-300">
                                        •
                                      </span>
                                      <FileText className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                                      {Object.keys(service.documents).length}{" "}
                                      Documents
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="flex items-start gap-2">
                              <span
                                className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium ${getStatusColor(
                                  service.status
                                )}`}
                              >
                                {service.status}
                              </span>
                              {service.paymentStatus && (
                                <span
                                  className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium ${
                                    service.paymentStatus === "complete"
                                      ? "bg-green-100 text-green-800"
                                      : "bg-yellow-100 text-yellow-800"
                                  }`}
                                >
                                  {service.paymentStatus}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ml-4">
                          <ChevronRight className="h-5 w-5 text-gray-400 group-hover:text-indigo-500 transition-colors" />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "overview":
        return renderOverviewContent();

      case "documents":
        return renderDocumentsSection();

      case "services":
        return renderServicesSection();
      case "activity":
        return (
          <div className="space-y-6">
            <h3 className="text-lg font-medium text-gray-900">
              Activity History
            </h3>
            <div className="bg-white rounded-lg border border-gray-200 shadow-sm p-6">
              <div className="space-y-6">
                {company?.applicationSteps?.map(
                  (step, index) =>
                    step.completedAt && (
                      <ActivityItem
                        key={index}
                        icon={
                          step.status === "approved"
                            ? CheckCircle2
                            : AlertCircle
                        }
                        title={`${step.name} ${
                          step.status === "approved" ? "Completed" : "Updated"
                        }`}
                        description={step.description}
                        date={step.completedAt}
                      />
                    )
                )}
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };
  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-50">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => navigate("/companies")}
            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Companies
          </button>
          <div className="flex space-x-4">
            <button
              onClick={() => navigate(`/companies/${id}/edit`)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <Edit className="w-4 h-4 mr-2" />
              Edit
            </button>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg">
          <div className="px-6 py-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className="h-12 w-12 rounded-full bg-indigo-100 flex items-center justify-center">
                  <Building2 className="h-6 w-6 text-indigo-600" />
                </div>
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    {company?.companyNames?.first?.english}
                  </h1>
                  <p className="text-sm text-gray-500">
                    {company?.companyDetails?.typeOfCompany} •{" "}
                    {company?.companyDetails?.location}
                  </p>
                </div>
              </div>
              <span
                className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(
                  company?.status
                )}`}
              >
                {company?.status}
              </span>
            </div>
          </div>

          <div className="border-t border-gray-200">
            <div className="px-6 py-4 flex space-x-4 overflow-x-auto">
              <TabButton tab="overview" icon={Building2} label="Overview" />
              <TabButton tab="services" icon={BriefcaseIcon} label="Services" />
              <TabButton
                tab="documents"
                icon={FileText}
                label="Documents"
                count={company?.documents?.length}
              />
              {/* <TabButton tab="tasks" icon={Table} label="Tasks" /> */}
            </div>
          </div>
          <div className="px-6 py-6">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailView;
