import React, { useState } from "react";
import {
  AlertCircle,
  CheckCircle,
  RefreshCw,
  X,
  ChevronRight,
  ExternalLink,
  Send,
  Link as LinkIcon,
  Link2,
} from "lucide-react";

const KYCStatusSection = ({ shareholder, companyId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [kycData, setKycData] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [hasChecked, setHasChecked] = useState(false);
  const [actionLoading, setActionLoading] = useState("");

  const fetchKYCStatus = async () => {
    if (loading) return;

    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/companies/${companyId}/kyc-status`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch KYC status");
      }

      const data = await response.json();
      const shareholderStatus = data.shareholderStatuses.find(
        (status) => status.shareholderId === shareholder.id
      );

      setKycData(shareholderStatus);
      setHasChecked(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const resendKYCRequest = async () => {
    setActionLoading("resend");
    try {
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/companies/${companyId}/kyc/resend`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            shareholderId: shareholder.id,
            email: shareholder.email,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to resend KYC request");
      }

      // Show success through a temporary message
      setError("KYC request resent successfully");
      setTimeout(() => setError(null), 3000);

      // Refresh KYC status
      await fetchKYCStatus();
    } catch (err) {
      setError(err.message);
    } finally {
      setActionLoading("");
    }
  };

  const getKYCLink = async () => {
    setActionLoading("link");
    try {
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/companies/${companyId}/kyc/link`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            shareholderId: shareholder.id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to get KYC link");
      }

      const data = await response.json();

      // Copy link to clipboard
      await navigator.clipboard.writeText(data.link);

      // Show success through a temporary message
      setError("KYC link copied to clipboard");
      setTimeout(() => setError(null), 3000);
    } catch (err) {
      setError(err.message);
    } finally {
      setActionLoading("");
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "bg-green-100 text-green-800";
      case "declined":
        return "bg-red-100 text-red-800";
      case "processing":
        return "bg-yellow-100 text-yellow-800";
      case "review":
        return "bg-purple-100 text-purple-800";
      case "awaiting_input":
        return "bg-blue-100 text-blue-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "approved":
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case "declined":
        return <X className="w-5 h-5 text-red-500" />;
      case "processing":
        return <RefreshCw className="w-5 h-5 text-yellow-500" />;
      case "review":
        return <AlertCircle className="w-5 h-5 text-purple-500" />;
      case "awaiting_input":
        return <AlertCircle className="w-5 h-5 text-blue-500" />;
      default:
        return <AlertCircle className="w-5 h-5 text-gray-500" />;
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const shouldShowGetLink = (status) => {
    return !status || status !== "approved";
  };

  // Initial state - show action buttons
  if (!hasChecked && !loading) {
    return (
      <div className="mt-4 border rounded-lg p-4 bg-gray-50">
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-500">KYC Status</span>
          <div className="flex items-center space-x-2">
            <button
              onClick={fetchKYCStatus}
              className="inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-md text-indigo-700 bg-indigo-50 hover:bg-indigo-100"
            >
              <RefreshCw className="w-4 h-4 mr-2" />
              Check Status
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="mt-4 border rounded-lg p-4 bg-gray-50">
        <div className="flex items-center space-x-3">
          <div className="animate-pulse flex space-x-3 w-full">
            <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
            <div className="h-5 w-24 bg-gray-200 rounded-full"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="mt-4 border rounded-lg p-4 bg-red-50">
        <div className="flex items-center space-x-3">
          <AlertCircle className="w-5 h-5 text-red-500" />
          <div className="text-sm text-red-700">{error}</div>
          <button
            onClick={fetchKYCStatus}
            className="ml-auto text-sm text-red-700 hover:text-red-800"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-4 border rounded-lg overflow-hidden">
      <div
        className="p-4 bg-gray-50 flex items-center justify-between cursor-pointer hover:bg-gray-100 transition-colors"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="flex items-center space-x-3">
          {getStatusIcon(kycData?.workflowStatus?.status)}
          <span
            className={`px-2.5 py-1 text-xs font-medium rounded-full ${getStatusColor(
              kycData?.workflowStatus?.status
            )}`}
          >
            {kycData?.workflowStatus?.status?.replace("_", " ").toUpperCase() ||
              "PENDING"}
          </span>
          <span className="text-sm text-gray-500">
            Last updated:{" "}
            {kycData?.workflowStatus?.updatedAt
              ? formatDate(kycData.workflowStatus.updatedAt)
              : "Never"}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          {shouldShowGetLink(kycData?.workflowStatus?.status) && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                getKYCLink();
              }}
              disabled={actionLoading === "link"}
              className="p-1.5 text-purple-400 hover:text-purple-500 rounded-full hover:bg-purple-50 disabled:opacity-50"
            >
              <Link2 className="w-4 h-4" />
            </button>
          )}
          <button
            onClick={(e) => {
              e.stopPropagation();
              fetchKYCStatus();
            }}
            className="p-1.5 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-200"
          >
            <RefreshCw className="w-4 h-4" />
          </button>
          <ChevronRight
            className={`w-5 h-5 text-gray-400 transform transition-transform duration-200 ${
              expanded ? "rotate-90" : ""
            }`}
          />
        </div>
      </div>

      {expanded && kycData?.workflowStatus && (
        <div className="p-4 border-t bg-white">
          <dl className="space-y-4">
            {kycData.workflowStatus.dashboardUrl && (
              <div className="flex items-center justify-between">
                <dt className="text-sm font-medium text-gray-500">Dashboard</dt>
                <dd>
                  <a
                    href={kycData.workflowStatus.dashboardUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center text-sm text-indigo-600 hover:text-indigo-500"
                  >
                    View in Onfido
                    <ExternalLink className="w-4 h-4 ml-1" />
                  </a>
                </dd>
              </div>
            )}

            {kycData.workflowStatus.reasons?.length > 0 && (
              <div>
                <dt className="text-sm font-medium text-gray-500 mb-2">
                  Reasons
                </dt>
                <dd className="bg-gray-50 rounded-lg p-3">
                  <ul className="space-y-1">
                    {kycData.workflowStatus.reasons.map((reason, index) => (
                      <li
                        key={index}
                        className="text-sm text-gray-700 flex items-start"
                      >
                        <span className="mr-2">•</span>
                        <span>{reason}</span>
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>
            )}

            {kycData.workflowStatus.tags?.length > 0 && (
              <div>
                <dt className="text-sm font-medium text-gray-500 mb-2">Tags</dt>
                <dd className="flex flex-wrap gap-2">
                  {kycData.workflowStatus.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 text-xs rounded-full bg-gray-100 text-gray-700"
                    >
                      {tag}
                    </span>
                  ))}
                </dd>
              </div>
            )}
          </dl>
        </div>
      )}
    </div>
  );
};

export default KYCStatusSection;
