import React, { useState, useMemo } from 'react';
import { CheckCircle2, Clock, XCircle, AlertTriangle } from 'lucide-react';

export const DEFAULT_STEPS = [
  {
    key: "payment",
    name: "Payment",
    description: "Your payment has been received and your application is now processing."
  },
  {
    key: "kyc",
    name: "Verification Check",
    description: "Complete identity verification for you and all shareholders"
  },
  {
    key: "documentSubmission",
    name: "Document Submission",
    description: "Upload your company formation documents. We'll guide you through the required documents."
  },
  {
    key: "documentVerification",
    name: "Document Verification",
    description: "Our team is reviewing your documents for UAE compliance. We'll contact you if any updates are needed."
  },
  {
    key: "governmentSubmission",
    name: "Government Submission",
    description: "Your application is being processed by government authorities. We'll manage all communications on your behalf."
  },
  {
    key: "licenseIssuance",
    name: "License Ready",
    description: "Your company license and documentation package is complete and ready for collection."
  }
];

const ApplicationSteps = ({ company, onStepUpdate }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState('');

  // Use company steps if they exist, otherwise use defaults
  const steps = useMemo(() => {
    if (!company?.applicationSteps?.length) {
      return DEFAULT_STEPS;
    }
    
    // Ensure all default steps exist in company steps
    return DEFAULT_STEPS.map(defaultStep => {
      const companyStep = company.applicationSteps.find(step => step.key === defaultStep.key);
      return {
        ...defaultStep,
        ...companyStep
      };
    });
  }, [company?.applicationSteps]);

  const getStatusColor = (status) => {
    const statusColors = {
      processing: "bg-yellow-100 text-yellow-800",
      approved: "bg-green-100 text-green-800",
      pending: "bg-blue-100 text-blue-800",
      rejected: "bg-red-100 text-red-800"
    };
    return statusColors[status] || "bg-gray-100 text-gray-800";
  };

  const handleStatusChange = async (stepKey, newStatus) => {
    setLoading(stepKey);
    setError('');

    try {
      const response = await fetch(
        `https://api.instacorp.ae/api/admin/data/companies/${company._id}/steps/${stepKey}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            status: newStatus,
            name: steps.find(s => s.key === stepKey)?.name,
            description: steps.find(s => s.key === stepKey)?.description
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      const data = await response.json();
      
      // Update the entire steps array with the response from the server
      if (data.steps) {
        onStepUpdate(data.steps);
      }

    } catch (err) {
      setError('Failed to update status. Please try again.');
    } finally {
      setLoading('');
    }
  };

  return (
    <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
      <div className="border-b border-gray-200 px-6 py-4">
        <h3 className="text-lg font-medium text-gray-900">Application Progress</h3>
      </div>
      
      <div className="px-6 py-4">
        {error && (
          <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg flex items-center space-x-2">
            <AlertTriangle className="h-5 w-5 text-red-500" />
            <p className="text-sm text-red-800">{error}</p>
          </div>
        )}
        
        <div className="space-y-4">
          {steps.map((step, index) => (
            <div key={index} className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 transition-colors duration-150">
              <div className="flex items-center space-x-3">
                <div className="min-w-[150px]">
                  <select
                    value={step.status || ''}
                    onChange={(e) => handleStatusChange(step.key, e.target.value)}
                    disabled={loading === step.key}
                    className="w-full border border-gray-300 rounded-md shadow-sm p-2 
                             focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500
                             disabled:bg-gray-100 disabled:cursor-not-allowed
                             text-sm transition-colors duration-150"
                  >
                    <option value="">Select Status</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>

                <div className="flex items-center space-x-3">
                  {loading === step.key ? (
                    <div className="w-5 h-5 border-2 border-indigo-600 border-t-transparent rounded-full animate-spin" />
                  ) : (
                    <>
                      {step.status === "approved" && <CheckCircle2 className="w-5 h-5 text-green-500" />}
                      {step.status === "pending" && <Clock className="w-5 h-5 text-yellow-500" />}
                      {step.status === "rejected" && <XCircle className="w-5 h-5 text-red-500" />}
                      {!step.status && <Clock className="w-5 h-5 text-gray-300" />}
                    </>
                  )}
                </div>

                <div>
                  <p className="font-medium text-gray-900">{step.name}</p>
                  <p className="text-sm text-gray-500">{step.description}</p>
                  {step.completedAt && (
                    <p className="text-xs text-gray-400 mt-1">
                      Completed on {new Date(step.completedAt).toLocaleDateString()}
                    </p>
                  )}
                </div>
              </div>
              
              <span className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(step.status)}`}>
                {step.status || 'Not Set'}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApplicationSteps;