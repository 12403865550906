import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Search, 
  Plus, 
  MoreVertical, 
  Phone, 
  Mail, 
  Building2,
  LayoutGrid,
  List,
  Edit,
  Trash2,
  UserPlus
} from 'lucide-react';
import Header from "../Header";


const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedView, setSelectedView] = useState('grid');
  const [loading, setLoading] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const limit = 12;

  // Debounced search
  const debouncedSearch = useCallback(
    (() => {
      let timeoutId;
      return (value) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setSearchQuery(value);
          setCurrentPage(1); // Reset to first page on search
        }, 500);
      };
    })(),
    []
  );

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await window.fetch(
          `https://api.instacorp.ae/api/admin/data/users?page=${currentPage}&limit=${limit}&search=${searchQuery}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        const data = await response.json();
        setUsers(data.users);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [currentPage, searchQuery]);

  const handleUserClick = (userId, e) => {
    if (e.target.closest('.more-options')) {
      return;
    }
    navigate(`/users/${userId}`);
  };
  
  const handleMoreOptionsClick = (userId, e) => {
    e.stopPropagation();
    setActiveDropdown(activeDropdown === userId ? null : userId);
  };
  
  const handleAction = async (action, userId, e) => {
    e.stopPropagation();
    setActiveDropdown(null);
  
    switch (action) {
      case 'edit':
        navigate(`/users/${userId}/edit`);
        break;
      case 'delete':
        if (window.confirm('Are you sure you want to delete this user?')) {
          try {
            await window.fetch(`https://api.instacorp.ae/api/admin/data/users/${userId}`, {
              method: 'DELETE',
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
              },
            });
            setUsers(users.filter(user => user._id !== userId));  // Changed from id to _id
          } catch (error) {
            console.error("Error deleting user:", error);
          }
        }
        break;
      default:
        break;
    }
  };

  const UserCard = ({ user }) => (
    <div 
      className="bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-200 cursor-pointer p-6"
      onClick={(e) => handleUserClick(user._id, e)}
    >
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-4">
          <div className="h-12 w-12 rounded-full bg-indigo-100 flex items-center justify-center">
            <span className="text-lg font-semibold text-indigo-700">
              {user.first_name?.[0]}{user.last_name?.[0]}
            </span>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-900">
              {user.first_name} {user.last_name}
            </h3>
            <p className="text-sm text-gray-500">{user.role}</p>
          </div>
        </div>
        <div className="relative more-options">
  <button 
    className="text-gray-400 hover:text-gray-600 p-1"
    onClick={(e) => handleMoreOptionsClick(user._id, e)}  // Changed from user.id to user._id to match MongoDB
  >
    <MoreVertical size={20} />
  </button>
  {activeDropdown === user._id && (  // Changed from user.id to user._id
    <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
      <div className="py-1">
        <button
          className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
          onClick={(e) => handleAction('edit', user._id, e)}  // Changed from user.id to user._id
        >
          <Edit className="h-4 w-4 mr-2" />
          Edit
        </button>
        <button
          className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 flex items-center"
          onClick={(e) => handleAction('delete', user._id, e)}  // Changed from user.id to user._id
        >
          <Trash2 className="h-4 w-4 mr-2" />
          Delete
        </button>
      </div>
    </div>
  )}
</div>
      </div>
      
      <div className="mt-4 space-y-2">
        <div className="flex items-center text-sm text-gray-500">
          <Mail className="h-4 w-4 mr-2" />
          {user.email}
        </div>
        <div className="flex items-center text-sm text-gray-500">
          <Phone className="h-4 w-4 mr-2" />
          {user.phone_number || 'No phone number'}
        </div>
        <div className="flex items-center text-sm text-gray-500">
          <Building2 className="h-4 w-4 mr-2" />
          {user.company || 'No company'}
        </div>
      </div>
    </div>
  );

  const UserRow = ({ user }) => (
    <tr 
      className="hover:bg-gray-50 cursor-pointer"
      onClick={(e) => handleUserClick(user.id, e)}
    >
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center">
            <span className="text-sm font-semibold text-indigo-700">
              {user.first_name?.[0]}{user.last_name?.[0]}
            </span>
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{user.first_name} {user.last_name}</div>
            <div className="text-sm text-gray-500">{user.role}</div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {user.email}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {user.phone_number || 'No phone number'}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {user.company || 'No company'}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
      <div className="relative more-options">
  <button 
    className="text-gray-400 hover:text-gray-600 p-1"
    onClick={(e) => handleMoreOptionsClick(user._id, e)}  // Changed from user.id to user._id
  >
    <MoreVertical size={20} />
  </button>
  {activeDropdown === user._id && (  // Changed from user.id to user._id
    <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
      <div className="py-1">
        <button
          className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
          onClick={(e) => handleAction('edit', user._id, e)}  // Changed from user.id to user._id
        >
          <Edit className="h-4 w-4 mr-2" />
          Edit
        </button>
        <button
          className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 flex items-center"
          onClick={(e) => handleAction('delete', user._id, e)}  // Changed from user.id to user._id
        >
          <Trash2 className="h-4 w-4 mr-2" />
          Delete
        </button>
      </div>
    </div>
  )}
</div>
      </td>
    </tr>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col space-y-6">
          {/* Header */}
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Users</h1>
              <p className="mt-1 text-sm text-gray-500">
                Manage your team members and their account permissions here
              </p>
            </div>
            <button
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => navigate('/users/new')}
            >
              <UserPlus className="h-4 w-4 mr-2" />
              Add User
            </button>
          </div>

          {/* Search and View Toggle */}
          <div className="flex items-center justify-between">
            <div className="flex-1 max-w-md">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Search users..."
                  onChange={(e) => debouncedSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <button
                className={`p-2 rounded-md ${selectedView === 'grid' ? 'bg-gray-200' : 'hover:bg-gray-100'}`}
                onClick={() => setSelectedView('grid')}
              >
                <LayoutGrid className="h-5 w-5 text-gray-500" />
              </button>
              <button
                className={`p-2 rounded-md ${selectedView === 'list' ? 'bg-gray-200' : 'hover:bg-gray-100'}`}
                onClick={() => setSelectedView('list')}
              >
                <List className="h-5 w-5 text-gray-500" />
              </button>
            </div>
          </div>

          {/* Loading State */}
          {loading && (
            <div className="flex justify-center items-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
            </div>
          )}

          {/* Users Display */}
          {!loading && (
            selectedView === 'grid' ? (
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {users.map((user) => (
                  <UserCard key={user.id} user={user} />
                ))}
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {users.map((user) => (
                      <UserRow key={user.id} user={user} />
                    ))}
                  </tbody>
                </table>
              </div>
            )
          )}

          {/* Pagination */}
          <div className="flex items-center justify-between border-t border-gray-200 pt-4">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed border border-gray-300"
            >
              Previous
            </button>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-700">
                Page {currentPage} of {totalPages}
              </span>
              <span className="text-sm text-gray-700">
                Total Users: {users.length}
              </span>
            </div>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed border border-gray-300"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Click Outside Handler for Dropdowns */}
      {activeDropdown && (
        <div 
          className="fixed inset-0 z-0"
          onClick={() => setActiveDropdown(null)}
        />
      )}
    </div>
  );
};

export default UsersPage;