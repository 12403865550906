import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  Mail,
  Phone,
  Calendar,
  Edit,
  Trash2,
  Clock,
  CheckCircle2,
  XCircle,
  User,
  CreditCard,
  Building2,
  MessageSquare,
  FileText,
  BriefcaseIcon,
  BarChart3,
  Plus,
  History,
  CalendarIcon,
  FileIcon,
  Tag,
  Paperclip,
  CheckSquare,
} from "lucide-react";
import Header from "../Header";
import CompaniesTab from "./utils/CompaniesTab";
import CommunicationsTab from "./utils/CommunicationsTab";

const UserDetailPage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("overview");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(
          `https://api.instacorp.ae/api/admin/data/users/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) throw new Error("User not found");
        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, [id]);

  const ActivityEntry = ({ icon: Icon, title, description, date }) => (
    <div className="flex space-x-3 pb-4">
      <div className="flex-shrink-0">
        <div className="w-8 h-8 rounded-full bg-indigo-50 flex items-center justify-center">
          <Icon className="w-4 h-4 text-indigo-600" />
        </div>
      </div>
      <div className="flex-grow">
        <div className="text-sm font-medium text-gray-900">{title}</div>
        <div className="text-sm text-gray-500">{description}</div>
        <div className="text-xs text-gray-400 mt-1">{date}</div>
      </div>
    </div>
  );

  const TabButton = ({ tab, icon: Icon, label, count }) => (
    <button
      onClick={() => setActiveTab(tab)}
      className={`flex items-center px-4 py-2 text-sm font-medium rounded-lg ${
        activeTab === tab
          ? "bg-indigo-50 text-indigo-700"
          : "text-gray-600 hover:bg-gray-50"
      }`}
    >
      <Icon className="w-5 h-5 mr-2" />
      {label}
      {count !== undefined && (
        <span className="ml-2 bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full text-xs">
          {count}
        </span>
      )}
    </button>
  );

  // Shared Components
  const SectionHeader = ({ title, actionLabel, onAction }) => (
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-lg font-medium text-gray-900">{title}</h3>
      <button
        onClick={onAction}
        className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
      >
        <Plus className="w-4 h-4 mr-2" />
        {actionLabel}
      </button>
    </div>
  );

  const TaskCard = ({ task }) => (
    <div className="bg-white p-4 rounded-lg border border-gray-200">
      <div className="flex items-start justify-between">
        <div className="flex items-start space-x-3">
          <input
            type="checkbox"
            checked={task.completed}
            className="mt-1 h-4 w-4 text-indigo-600 rounded border-gray-300"
          />
          <div>
            <h4 className="text-sm font-medium text-gray-900">{task.title}</h4>
            <p className="text-sm text-gray-500 mt-1">{task.description}</p>
            <div className="flex items-center space-x-4 mt-2">
              <span className="flex items-center text-xs text-gray-500">
                <CalendarIcon className="w-3 h-3 mr-1" />
                {task.dueDate}
              </span>
              <span
                className={`px-2 py-1 text-xs rounded-full ${
                  task.priority === "high"
                    ? "bg-red-100 text-red-800"
                    : task.priority === "medium"
                    ? "bg-yellow-100 text-yellow-800"
                    : "bg-green-100 text-green-800"
                }`}
              >
                {task.priority}
              </span>
            </div>
          </div>
        </div>
        <button className="text-gray-400 hover:text-gray-600">
          <Edit className="w-4 h-4" />
        </button>
      </div>
    </div>
  );

  const DocumentCard = ({ document }) => (
    <div className="bg-white p-4 rounded-lg border border-gray-200">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-gray-50 rounded-lg">
            <FileIcon className="w-6 h-6 text-gray-400" />
          </div>
          <div>
            <h4 className="text-sm font-medium text-gray-900">
              {document.name}
            </h4>
            <p className="text-xs text-gray-500">
              {document.size} • {document.uploadedAt}
            </p>
          </div>
        </div>
        <div className="flex space-x-2">
          <button className="p-1 text-gray-400 hover:text-gray-600">
            <ArrowLeft className="w-4 h-4" />
          </button>
          <button className="p-1 text-gray-400 hover:text-gray-600">
            <Trash2 className="w-4 h-4" />
          </button>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex items-center space-x-2">
          <Tag className="w-3 h-3 text-gray-400" />
          <div className="flex space-x-1">
            {document.tags.map((tag, index) => (
              <span
                key={index}
                className="text-xs bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full"
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const ServiceCard = ({ service }) => (
    <div className="bg-white p-4 rounded-lg border border-gray-200">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-sm font-medium text-gray-900">{service.name}</h3>
          <p className="text-xs text-gray-500 mt-1">{service.status}</p>
          <p className="text-sm text-gray-700 mt-2">{service.description}</p>
        </div>
        <span
          className={`px-2 py-1 text-xs rounded-full ${
            service.status === "active"
              ? "bg-green-100 text-green-800"
              : "bg-gray-100 text-gray-800"
          }`}
        >
          {service.status}
        </span>
      </div>
    </div>
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case "overview":
        return (
          <div className="bg-white shadow rounded-lg p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex items-center space-x-3">
                <Mail className="h-5 w-5 text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Email</p>
                  <p className="text-sm text-gray-900">{user?.email}</p>
                </div>
              </div>

              <div className="flex items-center space-x-3">
                <Phone className="h-5 w-5 text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Phone Number
                  </p>
                  <p className="text-sm text-gray-900">
                    {user?.phone_number || "Not provided"}
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-3">
                <Building2 className="h-5 w-5 text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Company</p>
                  <p className="text-sm text-gray-900">
                    {user?.company || "Not provided"}
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-3">
                <Calendar className="h-5 w-5 text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Join Date</p>
                  <p className="text-sm text-gray-900">
                    {user?.created_at
                      ? new Date(user.created_at).toLocaleDateString()
                      : "Not available"}
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-3">
                <Clock className="h-5 w-5 text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Last Updated
                  </p>
                  <p className="text-sm text-gray-900">
                    {user?.updated_at
                      ? new Date(user.updated_at).toLocaleDateString()
                      : "Not available"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );

      case "documents":
        return (
          <div className="space-y-4">
            <SectionHeader
              title="Documents"
              actionLabel="Upload Document"
              onAction={() => setShowUploadModal(true)}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <DocumentCard
                document={{
                  name: "Business Proposal.pdf",
                  size: "2.4 MB",
                  uploadedAt: "2 days ago",
                  tags: ["proposal", "business"],
                }}
              />
              <DocumentCard
                document={{
                  name: "Service Agreement.docx",
                  size: "1.8 MB",
                  uploadedAt: "1 week ago",
                  tags: ["legal", "contract"],
                }}
              />
              <DocumentCard
                document={{
                  name: "Financial Report Q1.xlsx",
                  size: "3.2 MB",
                  uploadedAt: "1 month ago",
                  tags: ["finance", "report"],
                }}
              />
            </div>
          </div>
        );
      case "companies":
        return <CompaniesTab userId={user?._id} />;

      case "services":
        return (
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium text-gray-900">Services</h3>
              <button className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                <Plus className="w-4 h-4 mr-2" />
                Add Service
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <ServiceCard
                service={{
                  name: "Business Consulting",
                  status: "active",
                  description:
                    "Monthly business strategy and growth consulting",
                }}
              />
              <ServiceCard
                service={{
                  name: "Tax Advisory",
                  status: "pending",
                  description: "Annual tax planning and compliance",
                }}
              />
            </div>
          </div>
        );

      case "communications":
        return <CommunicationsTab userId={user?._id} />;

      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Header />
        <div className="flex justify-between items-center mb-8">
          <button
            onClick={() => navigate("/users")}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Users
          </button>
          <div className="flex space-x-4">
            <button
              onClick={() => navigate(`/users/${user._id}/edit`)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <Edit className="h-4 w-4 mr-2" />
              Edit User
            </button>
          </div>
        </div>

        {/* User Profile Header */}
        <div className="bg-white shadow rounded-lg mb-6">
          <div className="px-6 py-8">
            <div className="flex items-center">
              <div className="h-20 w-20 rounded-full bg-indigo-100 flex items-center justify-center">
                <span className="text-2xl font-semibold text-indigo-700">
                  {user?.first_name?.[0]}
                  {user?.last_name?.[0]}
                </span>
              </div>
              <div className="ml-6">
                <h1 className="text-2xl font-bold text-gray-900">
                  {user?.first_name} {user?.last_name}
                </h1>
                <div className="flex items-center mt-1">
                  {user?.emailVerified ? (
                    <div className="flex items-center text-green-600">
                      <CheckCircle2 className="h-4 w-4 mr-1" />
                      <span className="text-sm">Verified Account</span>
                    </div>
                  ) : (
                    <div className="flex items-center text-yellow-600">
                      <XCircle className="h-4 w-4 mr-1" />
                      <span className="text-sm">Unverified Account</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tabs and Content */}
        <div className="bg-white shadow rounded-lg">
          <div className="border-b border-gray-200">
            <div className="px-6 py-4 flex space-x-4">
              <div className="border-b border-gray-200">
                <div className="px-6 py-4 flex space-x-4 overflow-x-auto">
                  <TabButton tab="overview" icon={User} label="Overview" />
                  <TabButton
                    tab="companies"
                    icon={Building2}
                    label="Companies"
                  />
                  <TabButton
                    tab="communications"
                    icon={MessageSquare}
                    label="Communications"
                  />
                  {/* <TabButton tab="tasks" icon={CheckSquare} label="Tasks"  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="px-6 py-6">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailPage;
