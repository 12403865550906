import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { Tab } from "@headlessui/react";
import {
  PencilIcon,
  TrashIcon,
  PlusIcon,
  XMarkIcon,
  TagIcon,
  GlobeAltIcon,
  PhotoIcon,
  EyeIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Header from "./Header";
import { getAuthHeader } from "./utils";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentBlog, setCurrentBlog] = useState({
    id: null,
    title: "",
    content: "",
    image: null,
    altText: "",
    metaDescription: "",
    metaKeywords: [],
    slug: "",
    excerpt: "",
    category: "",
    tags: [],
    author: "",
    publishDate: "",
    readingTime: "",
    featuredImage: null,
    socialImage: null, // Specific image for social sharing
  });
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [loadingState, setLoadingState] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newTag, setNewTag] = useState("");
  const editorRef = useRef(null);
  const originalBlog = useRef({});
  const [showPreview, setShowPreview] = useState(false);
  const [isGeneratingSEO, setIsGeneratingSEO] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    limit: 9,
    total: 0,
  });

  const initialBlogState = {
    id: null,
    title: "",
    content: "",
    image: null,
    altText: "",
    metaDescription: "",
    metaKeywords: [],
    slug: "",
    excerpt: "",
    category: "",
    tags: [],
    author: "",
    publishDate: "",
    readingTime: "",
    featuredImage: null,
    socialImage: null,
    status: "draft", // Added status field
  };
  const [newKeyword, setNewKeyword] = useState("");

  // Add these helper functions
  const handleKeywordAdd = (e) => {
    e.preventDefault();
    if (newKeyword && !currentBlog.metaKeywords.includes(newKeyword.trim())) {
      setCurrentBlog((prev) => ({
        ...prev,
        metaKeywords: [...prev.metaKeywords, newKeyword.trim()],
      }));
      setNewKeyword(""); // Reset input after adding
    }
  };

  const handleKeywordRemove = (keywordToRemove) => {
    setCurrentBlog((prev) => ({
      ...prev,
      metaKeywords: prev.metaKeywords.filter(
        (keyword) => keyword !== keywordToRemove
      ),
    }));
  };

  const resetForm = () => {
    setCurrentBlog(initialBlogState);
    setNewTag("");
    setNewCategory("");
    setShowAddCategory(false);
    if (editorRef.current) {
      editorRef.current.setContent("");
    }
  };

  // Fetch categories from API
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "https://api.instacorp.ae/api/blog/categories",
        {
          headers: getAuthHeader(),
        }
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const generateSEO = async () => {
    try {
      setIsGeneratingSEO(true);
      const response = await axios.post(
        "https://api.instacorp.ae/api/admin/blog/generate-seo",
        {
          title: currentBlog.title,
          content: currentBlog.content,
        },
        {
          headers: getAuthHeader(),
        }
      );

      const { metaDescription, tags, keywords } = response.data;
      console.log(metaDescription);
      setCurrentBlog((prev) => ({
        ...prev,
        metaDescription,
        tags: [...new Set([...prev.tags, ...tags])],
        metaKeywords: [...new Set([...prev.metaKeywords, ...keywords])],
      }));
    } catch (error) {
      console.error("Error generating SEO:", error);
      alert("Failed to generate SEO content. Please try again.");
    } finally {
      setIsGeneratingSEO(false);
    }
  };

  // Add new category
  const handleAddCategory = async (e) => {
    e.preventDefault();
    if (!newCategory.trim()) return;

    try {
      const response = await axios.post(
        "https://api.instacorp.ae/api/admin/blog/categories",
        { name: newCategory.trim() },
        { headers: getAuthHeader() }
      );
      setCategories([...categories, response.data]);
      setNewCategory("");
      setShowAddCategory(false);
      setCurrentBlog({
        ...currentBlog,
        category: response.data._id, // Assuming the API returns the new category with an _id
      });
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const CategoryField = React.memo(
    ({
      showAddCategory,
      setShowAddCategory,
      newCategory,
      setNewCategory,
      handleAddCategory,
      currentBlog,
      handleInputChange,
      categories,
    }) => {
      // Create a memoized onChange handler
      const onCategoryInputChange = React.useCallback(
        (e) => {
          setNewCategory(e.target.value);
        },
        [setNewCategory]
      );

      return (
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <label className="block text-sm font-medium text-gray-700">
              Category
            </label>
            <button
              type="button"
              onClick={() => setShowAddCategory(!showAddCategory)}
              className="text-sm text-blue-600 hover:text-blue-500"
            >
              {showAddCategory ? "Cancel" : "Add New Category"}
            </button>
          </div>

          {showAddCategory ? (
            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={newCategory}
                onChange={onCategoryInputChange}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="New category name"
              />
              <button
                onClick={handleAddCategory}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Add
              </button>
            </div>
          ) : (
            <select
              name="category"
              value={currentBlog.category || ""}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">Select Category</option>
              {categories?.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
          )}
        </div>
      );
    }
  );

  const fetchBlogs = async (page = 1) => {
    setLoadingState({ ...loadingState, fetch: true });
    try {
      const response = await axios.get(`https://api.instacorp.ae/api/blog`, {
        headers: getAuthHeader(),
        params: {
          page,
          limit: pagination.limit,
        },
      });
  
      // Correctly handle the pagination data from response
      const { blogs: blogsData, pagination: paginationData } = response.data;
  
      setBlogs(Array.isArray(blogsData) ? blogsData : []);
      setPagination((prev) => ({
        ...prev,
        currentPage: paginationData.page,
        totalPages: paginationData.pages,
        total: paginationData.total,
        limit: prev.limit // Keep existing limit
      }));
    } catch (error) {
      console.error("Fetch blogs error:", error);
      setBlogs([]);
    } finally {
      setLoadingState({ ...loadingState, fetch: false });
    }
  };

  // Update useEffect to use pagination
  useEffect(() => {
    fetchBlogs(pagination.currentPage);
    fetchCategories();
  }, [pagination.currentPage]); // Re-fetch when page changes

  const deleteBlog = async (id) => {
    setLoadingState({ ...loadingState, [id]: true });

    try {
      await axios.delete(`https://api.instacorp.ae/api/admin/blog/${id}`, {
        headers: getAuthHeader(),
      });
      await fetchBlogs();
    } catch (error) {
      console.error("Delete blog error:", error);
      alert("Error deleting blog post. Please try again.");
    } finally {
      setLoadingState({ ...loadingState, [id]: false });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsSubmitting(true);
      const formData = new FormData();

      // Add all text fields
      formData.append("title", currentBlog.title);
      formData.append("content", currentBlog.content);
      formData.append("metaDescription", currentBlog.metaDescription || "");
      formData.append("excerpt", currentBlog.excerpt || "");
      formData.append("slug", currentBlog.slug);
      formData.append("category", currentBlog.category || "");
      formData.append("status", currentBlog.status || "draft");

      formData.append("author", currentBlog.author);

      // Add arrays as JSON strings
      formData.append("tags", JSON.stringify(currentBlog.tags));
      formData.append("metaKeywords", JSON.stringify(currentBlog.metaKeywords));

      if (currentBlog.featuredImage instanceof File) {
        formData.append("featuredImage", currentBlog.featuredImage);
      }

      // Make the API call
      if (currentBlog._id) {
        // Change from currentBlog.id to currentBlog._id
        await axios.put(
          `https://api.instacorp.ae/api/admin/blog/${currentBlog._id}`, // Remove /blog from URL
          formData,
          {
            headers: {
              ...getAuthHeader(),
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        await axios.post("https://api.instacorp.ae/api/admin/blog", formData, {
          headers: {
            ...getAuthHeader(),
            "Content-Type": "multipart/form-data",
          },
        });
      }

      // Refresh blogs and reset form
      await fetchBlogs();
      resetForm();
      setIsFormOpen(false);
    } catch (error) {
      console.error("Error submitting blog:", error);
      alert("Error submitting blog post. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageChange = (file, type) => {
    if (file) {
      // Create URL for preview
      const imageUrl = URL.createObjectURL(file);
      setCurrentBlog((prev) => ({
        ...prev,
        [type]: file,
        [`${type}Preview`]: imageUrl,
      }));
    }
  };

  // Cleanup URLs on unmount
  useEffect(() => {
    return () => {
      if (currentBlog.featuredImagePreview) {
        URL.revokeObjectURL(currentBlog.featuredImagePreview);
      }
      if (currentBlog.socialImagePreview) {
        URL.revokeObjectURL(currentBlog.socialImagePreview);
      }
    };
  }, []);

  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)+/g, "");
  };

  const calculateReadingTime = (content) => {
    const wordsPerMinute = 200;
    const textContent = content.replace(/<[^>]*>/g, "");
    const words = textContent.split(/\s+/).length;
    return Math.ceil(words / wordsPerMinute);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "title") {
      setCurrentBlog({
        ...currentBlog,
        [name]: value,
        slug: generateSlug(value),
      });
    } else {
      setCurrentBlog({ ...currentBlog, [name]: value });
    }
  };

  const handleTagAdd = (e) => {
    e.preventDefault();
    if (newTag && !currentBlog.tags.includes(newTag)) {
      setCurrentBlog({
        ...currentBlog,
        tags: [...currentBlog.tags, newTag],
      });
      setNewTag("");
    }
  };

  const handleTagRemove = (tagToRemove) => {
    setCurrentBlog({
      ...currentBlog,
      tags: currentBlog.tags.filter((tag) => tag !== tagToRemove),
    });
  };

  const handleEditorChange = (content) => {
    setCurrentBlog({
      ...currentBlog,
      content,
      readingTime: calculateReadingTime(content),
    });
  };

  const handleModalClose = () => {
    if (hasUnsavedChanges()) {
      if (
        window.confirm(
          "You have unsaved changes. Are you sure you want to close?"
        )
      ) {
        setIsFormOpen(false);
        // resetForm();
      }
    } else {
      setIsFormOpen(false);
      // resetForm();
    }
  };

  // Check for unsaved changes
  const hasUnsavedChanges = () => {
    return (
      JSON.stringify(originalBlog.current) !==
      JSON.stringify({
        title: currentBlog.title,
        content: currentBlog.content,
        tags: currentBlog.tags,
        metaDescription: currentBlog.metaDescription,
      })
    );
  };

  const PaginationControls = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
      const pages = [];
      const delta = 2; // Number of pages to show on each side of current page
  
      for (let i = 1; i <= totalPages; i++) {
        if (
          i === 1 || // First page
          i === totalPages || // Last page
          (i >= currentPage - delta && i <= currentPage + delta) // Pages around current
        ) {
          pages.push(i);
        } else if (pages[pages.length - 1] !== '...') {
          pages.push('...');
        }
      }
      
      return pages;
    };
  
    return (
      <div className="flex items-center justify-center space-x-2 mt-8">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage <= 1}
          className="p-2 rounded-md border border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </button>
  
        <div className="flex items-center space-x-2">
          {getPageNumbers().map((pageNum, idx) => {
            if (pageNum === '...') {
              return (
                <span key={`ellipsis-${idx}`} className="px-4 py-2">
                  {pageNum}
                </span>
              );
            }
  
            return (
              <button
                key={pageNum}
                onClick={() => onPageChange(pageNum)}
                className={`px-4 py-2 rounded-md ${
                  pageNum === currentPage
                    ? "bg-blue-600 text-white"
                    : "border border-gray-300 hover:bg-gray-50"
                }`}
              >
                {pageNum}
              </button>
            );
          })}
        </div>
  
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage >= totalPages}
          className="p-2 rounded-md border border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
        >
          <ChevronRightIcon className="h-5 w-5" />
        </button>
      </div>
    );
  };
  
  
  return (
    <div className="bg-gray-50 min-h-screen">
      <Header />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-4xl font-bold text-gray-800">Blog Dashboard</h1>
            <p className="text-gray-600 mt-2">
              Showing {blogs.length} of {pagination.total} posts
            </p>
          </div>
          <button
            onClick={() => setIsFormOpen(true)}
            className="bg-blue-600 text-white px-5 py-2 rounded-lg flex items-center shadow-lg hover:bg-blue-700 transition-colors focus:outline-none"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            New Post
          </button>
        </div>

        {loadingState.fetch ? (
          <div className="text-center text-indigo-600">Loading...</div>
        ) : (
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {blogs.map((blog) => (
              <>
                <div
                  key={blog._id}
                  className="bg-white p-4 rounded-lg shadow flex flex-col"
                >
                  <Link to={`/blog/${blog.slug}`} key={blog.slug}>
                    <img
                      src={blog.featuredImageUrl}
                      alt={blog.imageAltText || "Blog Image"}
                      className="rounded-lg mb-4 h-60 w-full object-cover"
                    />
                  </Link>
                  <h2 className="text-xl font-semibold mb-2 cursor-pointer">
                    {blog.title}
                  </h2>
                  <div
                    className="text-gray-600 mb-4"
                    dangerouslySetInnerHTML={{
                      __html: blog.content.substring(0, 100) + "...",
                    }}
                  />

                  <div className="mt-auto flex justify-between items-center">
                    <button
                      onClick={() => {
                        setCurrentBlog({
                          ...blog,
                          id: blog._id,
                          tags: Array.isArray(blog.tags) ? blog.tags : [], // Ensure tags is an array
                          metaKeywords: Array.isArray(blog.metaKeywords)
                            ? blog.metaKeywords
                            : [], // Ensure metaKeywords is an array
                          featuredImagePreview: blog.featuredImageUrl, // Set preview from existing URL
                          category: blog.category?._id || blog.category, // Handle both populated and unpopulated category
                        });
                        setIsFormOpen(true);
                      }}
                      className="text-gray-600 hover:text-gray-900"
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => deleteBlog(blog._id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              
              </>
            ))}
            <PaginationControls 
  currentPage={pagination.currentPage}
  totalPages={pagination.totalPages}
  onPageChange={fetchBlogs}
/>
          </div>
        )}
      </div>

      {isFormOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-start justify-center overflow-hidden z-50">
          <div className="relative bg-white rounded-lg w-full max-w-4xl my-4 mx-4 h-[90vh] flex flex-col">
            {/* Modal Header */}
            <div className="sticky top-0 bg-white px-6 py-4 border-b border-gray-200 flex justify-between items-center z-10">
              <h2 className="text-2xl font-semibold">
                {currentBlog.id ? "Edit Blog" : "Create Blog"}
              </h2>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => setShowPreview(!showPreview)}
                  className="p-2 text-gray-600 hover:text-gray-900 rounded-full hover:bg-gray-100"
                  title={showPreview ? "Hide Preview" : "Show Preview"}
                >
                  <EyeIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={handleModalClose}
                  className="p-2 text-gray-600 hover:text-gray-900 rounded-full hover:bg-gray-100"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
            </div>

            <div className="flex-1 overflow-y-auto px-6 py-4">
              <div className="flex gap-4">
                {/* Form Section */}
                <div className={`${showPreview ? "w-1/2" : "w-full"}`}>
                  <Tab.Group>
                    <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-6 sticky top-0 z-10">
                      <Tab
                        className={({ selected }) =>
                          `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                    ${
                      selected
                        ? "bg-white text-blue-700 shadow"
                        : "text-blue-500 hover:bg-white/[0.12] hover:text-blue-600"
                    }`
                        }
                      >
                        Content
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                    ${
                      selected
                        ? "bg-white text-blue-700 shadow"
                        : "text-blue-500 hover:bg-white/[0.12] hover:text-blue-600"
                    }`
                        }
                      >
                        SEO & Meta
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                    ${
                      selected
                        ? "bg-white text-blue-700 shadow"
                        : "text-blue-500 hover:bg-white/[0.12] hover:text-blue-600"
                    }`
                        }
                      >
                        Media
                      </Tab>
                    </Tab.List>

                    <Tab.Panels>
                      <Tab.Panel className="space-y-6">
                        {/* Content Panel */}

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Title
                            </label>
                            <input
                              type="text"
                              name="title"
                              value={currentBlog.title}
                              onChange={handleInputChange}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                              placeholder="Enter blog title..."
                            />
                          </div>
                          <CategoryField
                            showAddCategory={showAddCategory}
                            setShowAddCategory={setShowAddCategory}
                            newCategory={newCategory}
                            setNewCategory={setNewCategory}
                            handleAddCategory={handleAddCategory}
                            currentBlog={currentBlog}
                            handleInputChange={handleInputChange}
                            categories={categories}
                          />
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-2">
                            Content
                          </label>
                          <Editor
                            apiKey="l0qv3j98jj398n2msgzo94jq27odnzgei61s2ql6fgyfivfu"
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            value={currentBlog.content}
                            onEditorChange={handleEditorChange}
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "code",
                                "help",
                                "wordcount",
                                "codesample", // Added for code snippets
                              ],
                              toolbar:
                                "undo redo | formatselect | " +
                                "bold italic backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | " +
                                "removeformat | codesample | help",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              statusbar: true,
                              resize: false,
                              branding: false,
                            }}
                          />
                        </div>
                      </Tab.Panel>

                      <Tab.Panel className="space-y-6">
                        <div className="mb-6">
                          <button
                            onClick={generateSEO}
                            disabled={
                              isGeneratingSEO ||
                              !currentBlog.title ||
                              !currentBlog.content
                            }
                            className="w-full flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            {isGeneratingSEO ? (
                              <>
                                <svg
                                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                                Generating SEO Content...
                              </>
                            ) : (
                              <>
                                <GlobeAltIcon className="w-5 h-5 mr-2" />
                                Generate SEO Content
                              </>
                            )}
                          </button>
                          {(!currentBlog.title || !currentBlog.content) && (
                            <p className="mt-2 text-sm text-gray-500 text-center">
                              Please add title and content first to generate SEO
                              content
                            </p>
                          )}
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Author
                          </label>
                          <input
                            type="text"
                            name="author"
                            value={currentBlog.author}
                            onChange={handleInputChange}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 `}
                            placeholder="Enter author name"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Meta Description
                          </label>
                          <textarea
                            name="metaDescription"
                            value={currentBlog.metaDescription}
                            onChange={handleInputChange}
                            rows={3}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                          />
                          <p className="mt-1 text-sm text-gray-500">
                            {currentBlog.metaDescription.length}/160 characters
                          </p>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Meta Keywords
                          </label>
                          <div className="mt-1 flex items-center space-x-2">
                            <input
                              type="text"
                              value={newKeyword}
                              onChange={(e) => setNewKeyword(e.target.value)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                              placeholder="Add a keyword"
                            />
                            <button
                              onClick={handleKeywordAdd}
                              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                            >
                              Add
                            </button>
                          </div>
                        </div>

                        <div className="mt-2 flex flex-wrap gap-2">
                          {currentBlog.metaKeywords.map((keyword) => (
                            <span
                              key={keyword}
                              className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800"
                            >
                              {keyword}
                              <button
                                onClick={() => handleKeywordRemove(keyword)}
                                className="ml-2 text-green-600 hover:text-green-800"
                              >
                                <XMarkIcon className="h-4 w-4" />
                              </button>
                            </span>
                          ))}
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            URL Slug
                          </label>
                          <input
                            type="text"
                            name="slug"
                            value={currentBlog.slug}
                            onChange={handleInputChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                          />
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Tags
                          </label>
                          <div className="mt-1 flex items-center space-x-2">
                            <input
                              type="text"
                              value={newTag}
                              onChange={(e) => setNewTag(e.target.value)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                              placeholder="Add a tag"
                            />
                            <button
                              onClick={handleTagAdd}
                              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                            >
                              Add
                            </button>
                          </div>
                          <div className="mt-2 flex flex-wrap gap-2">
                            {currentBlog.tags.map((tag) => (
                              <span
                                key={tag}
                                className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
                              >
                                {tag}
                                <button
                                  onClick={() => handleTagRemove(tag)}
                                  className="ml-2 text-blue-600 hover:text-blue-800"
                                >
                                  <XMarkIcon className="h-4 w-4" />
                                </button>
                              </span>
                            ))}
                          </div>
                        </div>
                      </Tab.Panel>

                      <Tab.Panel className="space-y-6">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Featured Image
                          </label>
                          <div className="mt-1 flex flex-col items-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                            {currentBlog.featuredImagePreview ? (
                              <div className="relative w-full mb-4">
                                <img
                                  src={currentBlog.featuredImagePreview}
                                  alt="Featured image preview"
                                  className="w-full h-48 object-cover rounded-md"
                                />
                                <button
                                  onClick={() =>
                                    setCurrentBlog((prev) => ({
                                      ...prev,
                                      featuredImage: null,
                                      featuredImagePreview: null,
                                    }))
                                  }
                                  className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                                >
                                  <XMarkIcon className="h-4 w-4" />
                                </button>
                              </div>
                            ) : (
                              <div className="space-y-1 text-center">
                                <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />
                                <div className="flex text-sm text-gray-600">
                                  <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                    <span>Upload a file</span>
                                    <input
                                      type="file"
                                      className="sr-only"
                                      onChange={(e) =>
                                        handleImageChange(
                                          e.target.files?.[0],
                                          "featuredImage"
                                        )
                                      }
                                      accept="image/*"
                                    />
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>

                {/* Preview Section */}
                {showPreview && (
                  <div className="w-1/2 border-l border-gray-200 pl-4">
                    <div className="sticky top-0 bg-white pb-4">
                      <h3 className="text-lg font-medium text-gray-900">
                        Preview
                      </h3>
                    </div>
                    <div className="prose max-w-none">
                      <h1>{currentBlog.title}</h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentBlog.content,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Modal Footer */}
            <div className="sticky bottom-0 bg-white px-6 py-4 border-t border-gray-200 flex justify-between items-center">
              <div className="text-sm text-gray-500">
                {currentBlog.readingTime &&
                  `Reading time: ${currentBlog.readingTime} min`}
              </div>
              <div className="flex space-x-3">
                <button
                  type="button"
                  onClick={handleModalClose}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50"
                >
                  {isSubmitting
                    ? "Saving..."
                    : currentBlog.id
                    ? "Update Post"
                    : "Publish Post"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;
