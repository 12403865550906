import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import {
  ArrowLeft,
  Clock,
  User,
  Mail,
  Phone,
  AlertCircle,
  ExternalLink,
  Check,
  X,
  MessageSquare,
  Send
} from 'lucide-react';
import { getAuthHeader } from "./utils";

const TicketDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusOptions] = useState(["New", "Open", "InProgress", "Resolved", "Closed"]);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [reply, setReply] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchTicketDetails = async () => {
      try {
        setLoading(true);
        // Endpoint needs to be adjusted based on your API structure
        const response = await axios.get(
          `https://api.instacorp.ae/api/admin/data/tickets/${id}`,
          { headers: getAuthHeader() }
        );

        if (response.data.success) {
          setTicket(response.data.ticket);
        } else {
          setError("Failed to fetch ticket details");
        }
      } catch (err) {
        console.error("Error fetching ticket:", err);
        setError("An error occurred while fetching the ticket details");
      } finally {
        setLoading(false);
      }
    };

    fetchTicketDetails();
  }, [id]);

  const updateStatus = async (newStatus) => {
    try {
      const response = await axios.patch(
        `https://api.instacorp.ae/api/admin/data/tickets/${id}/status`,
        { status: newStatus },
        { headers: getAuthHeader() }
      );

      if (response.data.success) {
        setTicket({ ...ticket, status: newStatus });
      }
    } catch (err) {
      console.error("Error updating ticket status:", err);
    }
    setStatusDropdownOpen(false);
  };

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    if (!reply.trim()) return;

    try {
      setSubmitting(true);
      const response = await axios.post(
        `https://api.instacorp.ae/api/admin/data/tickets/${id}/reply`,
        { message: reply },
        { headers: getAuthHeader() }
      );

      if (response.data.success) {
        // Update the ticket replies if your API returns the updated ticket
        if (response.data.ticket) {
          setTicket(response.data.ticket);
        } else {
          // If not, you might want to fetch the ticket details again
          // or manually add the reply to the existing replies
          const newReply = {
            id: Date.now(), // Temporary ID
            message: reply,
            createdAt: new Date().toISOString(),
            isAdmin: true,
            // Add any other fields your replies have
          };
          
          setTicket({
            ...ticket,
            replies: [...(ticket.replies || []), newReply]
          });
        }
        setReply("");
      }
    } catch (err) {
      console.error("Error sending reply:", err);
    } finally {
      setSubmitting(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const getStatusBadge = (status) => {
    if (!status) return "bg-gray-100 text-gray-600";
    
    const statuses = {
      new: "bg-blue-100 text-blue-800",
      open: "bg-yellow-100 text-yellow-800",
      inprogress: "bg-purple-100 text-purple-800",
      resolved: "bg-green-100 text-green-800",
      closed: "bg-gray-100 text-gray-600",
    };
    return statuses[status.toLowerCase()] || "bg-gray-100 text-gray-600";
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex justify-center items-center py-16">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !ticket) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="bg-white shadow rounded-lg p-6">
            <div className="flex items-center text-red-500 mb-4">
              <AlertCircle className="h-6 w-6 mr-2" />
              <h2 className="text-xl font-semibold">Error Loading Ticket</h2>
            </div>
            <p className="text-gray-600">{error || "Ticket not found"}</p>
            <button
              onClick={() => navigate(-1)}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              Go Back
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="mb-4 inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-blue-500"
        >
          <ArrowLeft className="h-4 w-4 mr-1" />
          Back to Tickets
        </button>

        {/* Ticket Header */}
        <div className="bg-white shadow rounded-lg mb-6">
          <div className="p-6">
            <div className="flex flex-col md:flex-row md:items-start md:justify-between">
              <div className="flex-1">
                <h1 className="text-2xl font-bold text-gray-900 mb-2">
                  {ticket.ticketTitle}
                </h1>
                <div className="flex flex-wrap items-center space-x-4 mb-4">
                  <div className="flex items-center text-sm text-gray-500">
                    <Clock className="h-4 w-4 mr-1" />
                    Created: {formatDate(ticket.createdAt)}
                  </div>
                  {ticket.updatedAt && ticket.updatedAt !== ticket.createdAt && (
                    <div className="flex items-center text-sm text-gray-500">
                      <Clock className="h-4 w-4 mr-1" />
                      Updated: {formatDate(ticket.updatedAt)}
                    </div>
                  )}
                  <div className="flex items-center">
                    <span className="text-sm text-gray-500 mr-2">Type:</span>
                    <span className="px-2 py-0.5 text-xs rounded-full bg-gray-100 text-gray-800">
                      {ticket.ticketType}
                    </span>
                  </div>
                </div>
              </div>

            
            </div>
          </div>
        </div>

        {/* Ticket Content & User Info */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          {/* Ticket Details */}
          <div className="md:col-span-2 bg-white shadow rounded-lg p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Ticket Details</h2>
            <div className="prose max-w-none text-gray-700 whitespace-pre-line">
              {ticket.ticketBody}
            </div>
          </div>

          {/* User Information */}
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Submitter Information</h2>
            <div className="space-y-4">
              <div className="flex items-start">
                <User className="h-5 w-5 text-gray-400 mr-2 mt-0.5" />
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    {ticket.firstName} {ticket.lastName}
                  </h3>
                  {ticket.isGuestTicket && (
                    <span className="text-xs px-2 py-0.5 bg-orange-100 text-orange-800 rounded-full">
                      Guest
                    </span>
                  )}
                </div>
              </div>
              <div className="flex items-start">
                <Mail className="h-5 w-5 text-gray-400 mr-2 mt-0.5" />
                <div>
                  <h3 className="text-sm font-medium text-gray-900">Email</h3>
                  <p className="text-sm text-gray-500">{ticket.email}</p>
                </div>
              </div>
              {ticket.phone && (
                <div className="flex items-start">
                  <Phone className="h-5 w-5 text-gray-400 mr-2 mt-0.5" />
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">Phone</h3>
                    <p className="text-sm text-gray-500">{ticket.phone}</p>
                  </div>
                </div>
              )}
              {!ticket.isGuestTicket && ticket.userId && (
                <div className="mt-4">
                  <a
                    href={`/users/${ticket.userId}`}
                    className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  >
                    <ExternalLink className="h-4 w-4 mr-1" />
                    View User Profile
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Replies Section */}
      
      </div>
    </div>
  );
};

export default TicketDetailPage